import { UserRole } from 'utils/requests/company/companyRequests.types';

export const formatterUserRoles = (roles: UserRole[]) => {
  const roleArray: string[] = [];

  for (let i = 0; i < roles.length; i++) {
    if (roles[i].authority === 'ROLE_COMPANY') {
      roleArray.push('Empresa');
    } else if (roles[i].authority === 'ROLE_PASSENGER') {
      roleArray.push('Passageiro');
    } else if (roles[i].authority === 'ROLE_RIDE') {
      roleArray.push('Motorista');
    }
  }

  return roleArray;
};
