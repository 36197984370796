// COMPONENTS
import PendingRidesToAccept from './components/pendingRidesToAccept';

const DashboardDriver = () => {
  return (
    <div className="container">
      <PendingRidesToAccept />
      {/*
        Desativado por solicitação do cliente:
         Dia: 04/10
         Motivo: Cliente entende que os motorista não saberão utilizar essa área da melhor forma, porntanto optou por ocultar de forma
         temporária as informações de colocar corrida em andamento ou não.
       <PendingRidesToStart />
       */}
    </div>
  );
};

export default DashboardDriver;
