import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

import { AxiosRequestConfig } from 'axios';
import Swal from 'sweetalert2';

import { requestBackend } from 'utils/requests/requests';
import { RequestAllDriversResponse } from 'utils/requests/driver/driverRequests.types';

type UrlParams = {
  rideId: string;
};

type DriverData = {
  driverId: number;
  driverName?: string;
};

type FormData = {
  driverData: DriverData;
};

const AssignDriver = () => {
  const history = useHistory();
  const { rideId } = useParams<UrlParams>();
  const [allDrivers, setAllDrivers] = useState<RequestAllDriversResponse[]>();
  const [formattedDriver, setFormattedDriver] = useState<DriverData[]>([]);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    const forwardingSaveData = {
      ride: {
        id: Number(rideId),
      },
      driver: {
        driver: {
          id: formData.driverData.driverId,
        },
      },
    };

    const config: AxiosRequestConfig = {
      method: 'POST',
      url: '/fowards',
      withCredentials: true,
      data: forwardingSaveData,
    };

    requestBackend(config)
      .then(() => {
        Swal.fire(
          'Sucesso!',
          'Motorista solicitado com sucesso!',
          'success',
        ).then(() => {
          history.push('/myApp/menu/admin/pending-rides');
        });
      })
      .catch(() => {
        Swal.fire('Oops!', 'Falha ao solicitar motorista', 'error');
      });
  };

  useEffect(() => {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/users/driver/available?size=1000',
      withCredentials: true,
    };

    requestBackend(config)
      .then((response) => {
        setAllDrivers(response.data.content);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  useEffect(() => {
    const formattedDriverArray: DriverData[] = [];

    if (allDrivers?.length) {
      allDrivers.map((driver) =>
        formattedDriverArray.push({
          driverId: driver.driver.id,
          driverName: driver.driver.firstName + ' ' + driver.driver.lastName,
        }),
      );
    }

    setFormattedDriver(formattedDriverArray);
  }, [allDrivers]);

  return (
    <div className="p-3 mt-5 base-card">
      <div className="container">
        <h1 className="details-card-title mt-2">Solicitar motorista</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <h4 className="mb-4 text-secondary">
            Solicite um motorista para esta corrida:
          </h4>

          <div>
            <Controller
              name="driverData"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  classNamePrefix="singup-select"
                  options={formattedDriver}
                  getOptionLabel={(driver: DriverData) =>
                    `${driver.driverName}`
                  }
                  getOptionValue={(driver: DriverData) =>
                    String(driver.driverId)
                  }
                  placeholder="Selecione o motorista"
                  noOptionsMessage={() => 'Sem opções'}
                />
              )}
            />

            {errors.driverData && (
              <div
                className={`${
                  errors.driverData ? 'invalid-feedback d-block' : ''
                }`}
              >
                Campo obrigatório
              </div>
            )}
          </div>

          <button type="submit" className="btn btn-secondary mt-4 mb-4">
            Solicitar motorista
          </button>
        </form>
      </div>
    </div>
  );
};

export default AssignDriver;
