// REACT
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

// DEPENDENCIES
import Swal from 'sweetalert2';
import Select from 'react-select';
import { ThreeDots } from 'react-loader-spinner';

// UTILS
import history from 'utils/history';
import { getAuthData } from 'utils/storage';
import { getTokenData } from 'utils/requests/auth/auth';
import {
  requestBackendRide,
  requestBrazilianCities,
  requestBrazilianStates,
} from 'utils/requests/ride/rideRequests';
import { requestRaceFormatter } from 'utils/formatters/formatterRequest';
import { requestBackEndUserById } from 'utils/requests/user/userRequests';
import { CompanyUser } from 'utils/requests/company/companyRequests.types';
import { requestCompanyById } from 'utils/requests/company/companyRequests';

// COMPONENTS
import ButtonIcon from 'components/Buttons/ButtonIcon';

// TYPES
import { RequestRideFormData } from './RequestRide.types';

// STYLES
import './styles.css';
import { CurrentDateFormatter } from 'utils/formatters/fomatterDate';
import {
  extractNumbers,
  formatPhoneNumber,
  maskToPhoneNumber,
} from 'utils/formatters/fomatterInput';
import { RequestBackendRaceData } from 'utils/requests/ride/rideRequests.types';

type Props = {
  isManagerRequest?: boolean;
};

type PassengerCategory = {
  id: number;
  name: string;
};

type StateCategory = {
  id: number;
  nome: string;
  regiao: any;
  sigla: string;
};

type CityCategory = {
  id: number;
  nome: string;
};

const RequestRide = ({ isManagerRequest = false }: Props) => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>();
  const [logedUserId, setLogedUserId] = useState<number>(0);
  const [passengerOptions, setPassengerOptions] =
    useState<PassengerCategory[]>();
  const [passengerCostCenter, setPassengerCostCenter] = useState<string>();
  const [selectedPassenger, setSelectedPassenger] =
    useState<PassengerCategory>();
  const [phoneNumber, setPhoneNumber] = useState('');

  const [stateOptions, setStateOptions] = useState<StateCategory[]>([]);
  const [selectedState, setSelectedState] = useState<StateCategory>();

  const [stateDestinyOptions, setStateDestinyOptions] = useState<
    StateCategory[]
  >([]);
  const [selectedDestinyState, setSelectedDestinyState] =
    useState<StateCategory>();
  const [selectedDestinyCity, setSelectedDestinyCity] =
    useState<CityCategory>();

  const [citiesOptions, setCitiesOptions] = useState<CityCategory[]>([]);
  const [selectedCity, setSelectedCity] = useState<CityCategory>();

  const formattedCurrentDate = CurrentDateFormatter();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<RequestRideFormData>();

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhone);
    setValue('contact', formattedPhone);
  };

  useEffect(() => {
    requestBrazilianStates()
      .then((response) => {
        setStateOptions(response.data);
      })
      .catch((error) => {
        console.log('request states error', error);
      });
  }, []);

  useEffect(() => {
    requestBrazilianStates()
      .then((response) => {
        setStateDestinyOptions(response.data);
      })
      .catch((error) => {
        console.log('request states error', error);
      });
  }, []);

  useEffect(() => {
    selectedState?.id &&
      requestBrazilianCities(String(selectedState.id))
        .then((response) => {
          setCitiesOptions(response.data);
        })
        .catch((error) => {
          console.log('request cities error', error);
        });
  }, [selectedState?.id]);

  useEffect(() => {
    selectedDestinyState?.id &&
      requestBrazilianCities(String(selectedDestinyState.id))
        .then((response) => {
          setCitiesOptions(response.data);
        })
        .catch((error) => {
          console.log('request cities error', error);
        });
  }, [selectedDestinyState?.id]);

  useEffect(() => {
    setLogedUserId(getAuthData().userId);
  }, []);

  useEffect(() => {
    if (isManagerRequest) {
      requestCompanyById()
        .then((response) => {
          setCompanyUsers(response.data.user);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isManagerRequest]);

  useEffect(() => {
    const userList: PassengerCategory[] = [];

    companyUsers &&
      companyUsers
        .filter((user) => user.isActivated || user.isActivated == null)
        .map((user) =>
          userList.push({
            id: user.id,
            name: `${user.firstName + ' ' + user.lastName}`,
          }),
        );

    setPassengerOptions(userList);
  }, [companyUsers]);

  useEffect(() => {
    logedUserId &&
      !isManagerRequest &&
      requestBackEndUserById(String(logedUserId)).then((response) => {
        setPassengerCostCenter(response.data.costCenter);
        setPhoneNumber(maskToPhoneNumber(String(response.data.phoneNumber)));
      });

    passengerCostCenter && setValue('costCenter', passengerCostCenter);
  }, [
    logedUserId,
    passengerCostCenter,
    isManagerRequest,
    setValue,
    setPassengerCostCenter,
  ]);

  const onSubmit = (formData: RequestRideFormData) => {
    if (isManagerRequest && !selectedPassenger) {
      Swal.fire({
        title: 'Selecione o passageiro.',
        icon: 'warning',
      });
    } else {
      if (!isManagerRequest) {
        formData.passengerData = {
          id: logedUserId,
          name: '',
        };
      }

      formData.applicantEmail = getTokenData()!.user_name;
      formData.applicantName = getAuthData().userFirstName;

      if (isManagerRequest) {
        formData.passengerData = selectedPassenger!;
      }

      const requestRaceData = requestRaceFormatter(formData);
      const formattedPhoneNumber: string = extractNumbers(
        formData.contact ?? '',
      );
      const requestRaceFormattedData: RequestBackendRaceData = {
        ...requestRaceData,
        ride: {
          ...requestRaceData.ride,
          contact: formattedPhoneNumber,
        },
      };

      setIsLoading(true);

      requestBackendRide(requestRaceFormattedData)
        .then(() => {
          Swal.fire(
            'Sucesso!',
            'Corrida solicitado com sucesso!',
            'success',
          ).then(() => {
            if (!isManagerRequest) {
              history.push('/myapp/menu/passenger/ride/current-ride');
            } else {
              history.push('/myapp/menu/ride-history');
            }
          });
          setHasError(false);
          setIsLoading(false);
        })
        .catch((error) => {
          setHasError(true);
          setIsLoading(false);
        });
    }
  };

  const setCostCenter = (selectedUserData: PassengerCategory) => {
    const companyUserId: number = selectedUserData.id;

    if (companyUsers) {
      const companyMemberData: CompanyUser | undefined = companyUsers.find(
        (user) => user.id === companyUserId,
      );

      if (companyMemberData) {
        const maskedPhoneNumber = maskToPhoneNumber(
          companyMemberData.phoneNumber,
        );
        setValue('costCenter', companyMemberData.costCenter);
        setValue('contact', maskedPhoneNumber);
        setPhoneNumber(maskedPhoneNumber);
      }
    }
  };

  return (
    <div className="base-card p-5 mt-5">
      <form onSubmit={handleSubmit(onSubmit)} className="container">
        {hasError && (
          <div className="alert alert-danger">
            Erro ao tentar solicitar corrida
          </div>
        )}

        {isManagerRequest && (
          <div className="request-race-choose-passenger mb-2">
            <div className="request-race-choose-passenger-option">
              <label className="mb-2" htmlFor="passengerData">
                Selecione o passageiro:
              </label>

              <Controller
                name="passengerData"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={passengerOptions}
                    classNamePrefix="singup-select"
                    placeholder="Passageiro"
                    getOptionLabel={(passenger: PassengerCategory) =>
                      `${passenger.name}`
                    }
                    getOptionValue={(passenger: PassengerCategory) =>
                      String(passenger.id)
                    }
                    onChange={(value) => {
                      setCostCenter(value as PassengerCategory);
                      setSelectedPassenger(value as PassengerCategory);
                    }}
                    value={selectedPassenger}
                    inputId="passengerData"
                  />
                )}
              />
            </div>
            {errors.passengerData && (
              <div
                className={`${
                  errors.passengerData ? 'invalid-feedback d-block' : ''
                }`}
              >
                Campo obrigatório
              </div>
            )}
          </div>
        )}

        <div className="mb-2 row">
          <div className="col-lg">
            <label className="mb-2 col-lg">Insira a data:</label>
            <input
              {...register('scheduleDate', {
                required: 'Campo obrigatório',
              })}
              type="date"
              min={formattedCurrentDate}
              className={`form-control base-input ${
                errors.scheduleDate ? 'is-invalid' : ''
              }`}
              placeholder="Data da utilização"
              name="scheduleDate"
            />
            <div className="invalid-feedback d-block">
              {errors.scheduleDate?.message}
            </div>
          </div>

          <div className="col-lg">
            <label className="mb-2 col-lg">Insira o horário:</label>
            <div className="col-lg">
              <input
                {...register('scheduleTime', {
                  required: 'Campo obrigatório',
                })}
                type="time"
                className={`form-control base-input ${
                  errors.scheduleTime ? 'is-invalid' : ''
                }`}
                placeholder="Horário da utilização"
                name="scheduleTime"
              />
              <div className="invalid-feedback d-block">
                {errors.scheduleTime?.message}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <label className="mb-2" htmlFor="passengerData">
            Local de origem:
          </label>

          <div className="request-race-choose-passenger-option">
            <Select
              options={stateOptions}
              classNamePrefix="singup-select"
              placeholder="Selecione o estado de origem"
              getOptionLabel={(state: StateCategory) => `${state.nome}`}
              getOptionValue={(state: StateCategory) => String(state.id)}
              onChange={(value) => {
                value && setSelectedState(value);
              }}
              value={selectedState}
              isSearchable
            />
          </div>

          <div className="mt-2">
            <Controller
              name="originCity"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  classNamePrefix="singup-select"
                  placeholder="Selecione a cidade de origem"
                  noOptionsMessage={() => 'Selecione um estado de origem'}
                  options={citiesOptions}
                  getOptionLabel={(city: CityCategory) => `${city.nome}`}
                  getOptionValue={(city: CityCategory) => String(city.id)}
                  onChange={(city) => {
                    if (city?.nome) {
                      setSelectedCity(city);
                      setValue('originCity', city.nome);
                    }
                  }}
                  value={selectedCity}
                  inputId="origemState"
                  isSearchable
                />
              )}
            />
            {errors.originCity && (
              <div
                className={`${
                  errors.originCity ? 'invalid-feedback d-block' : ''
                }`}
              >
                Campo obrigatório
              </div>
            )}
          </div>

          <div>
            <input
              {...register('originAddress', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input mt-2 ${
                errors.originAddress ? 'is-invalid' : ''
              }`}
              placeholder="Endereço de origem"
              name="originAddress"
            />
            <div className="invalid-feedback d-block">
              {errors.originAddress?.message}
            </div>
          </div>
        </div>

        <div className="row">
          <label className="mb-2 mt-2" htmlFor="passengerData">
            Local de destino:
          </label>

          <div className="request-race-choose-passenger-option">
            <Select
              options={stateDestinyOptions}
              classNamePrefix="singup-select"
              placeholder="Selecione o estado de destino"
              getOptionLabel={(state: StateCategory) => `${state.nome}`}
              getOptionValue={(state: StateCategory) => String(state.id)}
              onChange={(value) => {
                value && setSelectedDestinyState(value);
              }}
              value={selectedDestinyState}
              isSearchable
            />
          </div>

          <div className="mt-2">
            <Controller
              name="destinyCity"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  classNamePrefix="singup-select"
                  placeholder="Selecione a cidade de destino"
                  noOptionsMessage={() => 'Selecione um estado de destino'}
                  options={citiesOptions}
                  getOptionLabel={(city: CityCategory) => `${city.nome}`}
                  getOptionValue={(city: CityCategory) => String(city.id)}
                  onChange={(city) => {
                    if (city?.nome) {
                      setSelectedDestinyCity(city);
                      setValue('destinyCity', city.nome);
                    }
                  }}
                  value={selectedDestinyCity}
                  inputId="origemState"
                  isSearchable
                />
              )}
            />
            {errors.destinyCity && (
              <div
                className={`${
                  errors.destinyCity ? 'invalid-feedback d-block' : ''
                }`}
              >
                Campo obrigatório
              </div>
            )}
          </div>

          <div className="col-lg mb-2 mt-2">
            <input
              {...register('destinyAddress', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.destinyAddress ? 'is-invalid' : ''
              }`}
              placeholder="Endereço de destino"
              name="destinyAddress"
            />
            <div className="invalid-feedback d-block">
              {errors.destinyAddress?.message}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg mb-2">
            <input
              {...register('costCenter', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.costCenter ? 'is-invalid' : ''
              }`}
              placeholder="Centro de custo"
              name="costCenter"
            />
            <div className="invalid-feedback d-block">
              {errors.costCenter?.message}
            </div>
          </div>

          <div className="col-lg mb-2">
            <input
              {...register('contact', {
                required: 'Campo obrigatório',
                minLength: {
                  value: 11,
                  message: 'O número de telefone deve conter 11 dígitos',
                },
              })}
              type="text"
              className={`form-control base-input ${
                errors.contact ? 'is-invalid' : ''
              }`}
              maxLength={15}
              placeholder="Contato"
              name="contact"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            <div className="invalid-feedback d-block">
              {errors.contact?.message}
            </div>
          </div>
        </div>

        <div className="mb-4">
          <input
            {...register('observations')}
            type="text"
            className={`form-control base-input ${
              errors.observations ? 'is-invalid' : ''
            }`}
            placeholder="Observações"
            name="observations"
          />
          <div className="invalid-feedback d-block">
            {errors.observations?.message}
          </div>
        </div>

        {isLoading ? (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            visible={true}
            wrapperClass="login-loading"
          />
        ) : (
          <ButtonIcon text="Solicitar corrida" />
        )}
      </form>
    </div>
  );
};

export default RequestRide;
