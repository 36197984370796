// REACT
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

// DEPENDENCIES
import Swal from 'sweetalert2';
import Select from 'react-select';
import { ThreeDots } from 'react-loader-spinner';

// UTILS
import history from 'utils/history';
import { getAuthData } from 'utils/storage';
import { getTokenData } from 'utils/requests/auth/auth';
import {
  requestBackendRideOperator,
  requestBrazilianCities,
  requestBrazilianStates,
} from 'utils/requests/ride/rideRequests';
import { requestRaceFormatter } from 'utils/formatters/formatterRequest';
import { requestBackEndUserById } from 'utils/requests/user/userRequests';
import { CompanyDataResponse } from 'utils/requests/company/companyRequests.types';
import { requestBackEndAllCompanies } from 'utils/requests/company/companyRequests';

// COMPONENTS
import ButtonIcon from 'components/Buttons/ButtonIcon';

// TYPES
import { RequestRideFormData } from '../RequestRide/RequestRide.types';

// STYLES
import './styles.css';
import { CurrentDateFormatter } from 'utils/formatters/fomatterDate';
import {
  extractNumbers,
  formatPhoneNumber,
  maskToPhoneNumber,
} from 'utils/formatters/fomatterInput';
import { Ride } from '../../utils/types/entities.types';

type Props = {
  isManagerRequest?: boolean;
};
type StateCategory = {
  id: number;
  nome: string;
  regiao: any;
  sigla: string;
};

type CityCategory = {
  id: number;
  nome: string;
};

const fixedCities: CityCategory[] = [
  'Fábrica Jacareí',
  'Fábrica de Mogi',
  'Fábrica de Suzano',
  'Fábrica de Limeira',
  'Escritório Central SP',
  'Aeroporto de Guarulhos',
  'Aeroporto de Congonhas',
  'Aeroporto de Campinas',
].map((nome) => ({
  id: Math.floor(Math.random() * 1_000_000_000),
  nome,
}));

const RequestRideOperator = ({ isManagerRequest = false }: Props) => {
  const [companies, setCompanies] = useState<CompanyDataResponse[]>();
  const [selectedCompany, setSelectedCompany] = useState<CompanyDataResponse>();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logedUserId, setLogedUserId] = useState<number>(0);
  const [passengerCostCenter, setPassengerCostCenter] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState('');

  const [stateOptions, setStateOptions] = useState<StateCategory[]>([]);
  const [selectedState, setSelectedState] = useState<StateCategory>();

  const [stateDestinyOptions, setStateDestinyOptions] = useState<
    StateCategory[]
  >([]);
  const [selectedDestinyState, setSelectedDestinyState] =
    useState<StateCategory>();
  const [selectedDestinyCity, setSelectedDestinyCity] =
    useState<CityCategory>();

  const [citiesOptions, setCitiesOptions] =
    useState<CityCategory[]>(fixedCities);
  const [selectedOriginCity, setSelectedOriginCity] = useState<CityCategory>();

  const formattedCurrentDate = CurrentDateFormatter();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm<RequestRideFormData>();

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhone);
    setValue('contact', formattedPhone);
  };

  useEffect(() => {
    requestBrazilianStates()
      .then((response) => {
        setStateOptions(response.data);
      })
      .catch((error) => {
        console.log('request states error', error);
      });
  }, []);

  useEffect(() => {
    requestBrazilianStates()
      .then((response) => {
        setStateDestinyOptions(response.data);
      })
      .catch((error) => {
        console.log('request states error', error);
      });
  }, []);

  useEffect(() => {
    selectedState?.id &&
      requestBrazilianCities(String(selectedState.id))
        .then((response) => {
          setCitiesOptions([...fixedCities, ...response.data]);
        })
        .catch((error) => {
          console.log('request cities error', error);
        });
  }, [selectedState?.id]);

  useEffect(() => {
    selectedDestinyState?.id &&
      requestBrazilianCities(String(selectedDestinyState.id))
        .then((response) => {
          setCitiesOptions([...fixedCities, ...response.data]);
        })
        .catch((error) => {
          console.log('request cities error', error);
        });
  }, [selectedDestinyState?.id]);

  useEffect(() => {
    setLogedUserId(getAuthData().userId);
  }, []);

  useEffect(() => {
    logedUserId &&
      !isManagerRequest &&
      requestBackEndUserById(String(logedUserId)).then((response) => {
        setPassengerCostCenter(response.data.costCenter);
        setPhoneNumber(maskToPhoneNumber(String(response.data.phoneNumber)));
      });

    passengerCostCenter && setValue('costCenter', passengerCostCenter);
  }, [
    logedUserId,
    passengerCostCenter,
    isManagerRequest,
    setValue,
    setPassengerCostCenter,
  ]);

  useEffect(() => {
    requestBackEndAllCompanies().then((response) => {
      setCompanies(response.data.content);
      setIsLoading(false);
    });
  }, []);

  const onSubmit = (formData: RequestRideFormData) => {
    formData.applicantEmail = getTokenData()!.user_name;
    formData.applicantName = getAuthData().userFirstName;

    const requestRaceData = requestRaceFormatter(formData);
    const formattedPhoneNumber: string = extractNumbers(formData.contact ?? '');

    const requestRaceFormattedData: Ride = {
      ...requestRaceData.ride,
      contact: formattedPhoneNumber,
      applicantEmail: formData.applicantEmail,
      applicantName: formData.applicantName,
    };
    console.table(requestRaceFormattedData);
    setIsLoading(true);
    requestBackendRideOperator(requestRaceFormattedData)
      .then(() => {
        Swal.fire(
          'Sucesso!',
          'Corrida solicitado com sucesso!',
          'success',
        ).then(() => {
          history.push('/myApp/menu/admin/pending-rides');
        });
        setHasError(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setHasError(true);
        setIsLoading(false);
      });
  };

  function extractData(text: string): Record<string, string> {
    const data: Record<string, string> = {};
    data.beneficiario = text.match(/Beneficiário:\s*(.*)/)?.[1] || '';
    let telefone =
      text.match(/Telefone de Contato:\s*([\d\s\(\)\-\+]+)/)?.[1] || '';
    // Remover todos os caracteres não numéricos
    telefone = telefone.replace(/\D/g, '');

    // Verificar e formatar o telefone corretamente
    if (telefone.length === 11) {
      // Adiciona o código do Brasil se o número tiver apenas DDD e número
      telefone = `+55 (${telefone.substring(0, 2)}) ${telefone.substring(2)}`;
    } else if (telefone.length === 13 && telefone.startsWith('55')) {
      // Já tem o código do Brasil, então só formatamos
      telefone = `+${telefone.substring(0, 2)} (${telefone.substring(2, 4)}) ${telefone.substring(4)}`;
    }
    data.telefone = telefone;
    data.origem = text.match(/Origem:\s*(.*)/)?.[1].trim() || '';
    data.destino = text.match(/Destino:\s*(.*)/)?.[1].trim() || '';
    const dataHoraMatch = text.match(
      /Data e Hora da utilização:\s*(\d{2})\/(\d{2})\/(\d{4})\s*(\d{2}:\d{2})/,
    );
    if (dataHoraMatch) {
      data.data = `${dataHoraMatch[3]}-${dataHoraMatch[2]}-${dataHoraMatch[1]}`; // Formato YYYY-MM-DD
      data.hora = dataHoraMatch[4];
    } else {
      data.data = '';
      data.hora = '';
    }
    data.centroDeCusto = text.match(/Centro de Custo:\s*(.*)/)?.[1] || '';
    data.observacao = text.match(/Observação:\s*(.*)/)?.[1] || '';
    const origemMatch = text.match(/Origem:.*\|\s*(.*)\s*-\s*(\w{2})/);
    data.cidadeOrigem = origemMatch?.[1] || '';
    data.estadoOrigem = origemMatch?.[2] || '';
    const destinoMatch = text.match(/Destino:.*\|\s*(.*)\s*-\s*(\w{2})/);
    data.cidadeDestino = destinoMatch?.[1] || '';
    data.estadoDestino = destinoMatch?.[2] || '';
    setValue('costCenter', data.centroDeCusto);
    const formattedPhone = formatPhoneNumber(data.telefone);
    setValue('contact', formattedPhone);
    setPhoneNumber(formattedPhone);
    setValue('originAddress', data.origem);
    setValue('destinyAddress', data.destino);
    setValue('observations', data.observacao);
    setValue('scheduleDate', data.data);
    setValue('scheduleTime', data.hora);
    setValue('favoredName', data.beneficiario);

    setSelectedState(
      stateOptions.find((item) => item.sigla === data.estadoOrigem),
    );
    setSelectedDestinyState(
      stateOptions.find((item) => item.sigla === data.estadoDestino),
    );
    const normalizeString = (str: string) =>
      str
        .normalize('NFD')
        .replace(/[̀-ͯ]/g, '')
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '')
        .replace(/\s+/g, '');
    const originCity = citiesOptions.find(
      (item) =>
        normalizeString(item.nome) === normalizeString(data.cidadeOrigem),
    );
    const destinityCity = citiesOptions.find(
      (item) =>
        normalizeString(item.nome) === normalizeString(data.cidadeDestino),
    );

    if (originCity != null) {
      setSelectedOriginCity(originCity);
      setValue('originCity', originCity.nome);
    }

    if (destinityCity != null) {
      setSelectedDestinyCity(destinityCity);
      setValue('destinyCity', destinityCity.nome);
    }

    return data;
  }

  const handleRawInfo = (handleRawInfo: ChangeEvent<HTMLTextAreaElement>) => {
    extractData(handleRawInfo.target.value);
  };

  return (
    <div className="base-card p-5 mt-5">
      <form onSubmit={handleSubmit(onSubmit)} className="container">
        {hasError && (
          <div className="alert alert-danger">
            Erro ao tentar solicitar corrida
          </div>
        )}
        <div className="row">
          <div className="col-lg">
            <label className="mb-2  col-lg">Número de solicitação:</label>
            <input
              {...register('externalRideId')}
              type="text"
              className={`form-control base-input `}
              placeholder="Número de solicitação externo"
              name="externalRideId"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg">
            <label className="mb-2 mt-2 col-lg">Informações da corrida:</label>
            <textarea
              className={`form-control base-input `}
              name="rideInfo"
              placeholder="Cole aqui as informações do email recebido"
              onChange={(rawInfo) => {
                handleRawInfo(rawInfo);
              }}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="mt-2">
            <label className="mb-2 col-lg">Empresa:</label>
            <Controller
              name="companyId"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  classNamePrefix="singup-select"
                  placeholder="Selecione a empresa "
                  noOptionsMessage={() => 'Nenhuma empresa cadastrada'}
                  options={companies}
                  getOptionLabel={(company: CompanyDataResponse) =>
                    `${company.name}`
                  }
                  getOptionValue={(company: CompanyDataResponse) =>
                    String(company.id)
                  }
                  onChange={(company) => {
                    if (company != null) {
                      setSelectedCompany(company);
                      setValue('companyId', company.id);
                    }
                  }}
                  value={selectedCompany}
                  inputId="companyId"
                  isSearchable
                />
              )}
            />
            {errors.companyId && (
              <div
                className={`${
                  errors.companyId?.message ? 'invalid-feedback d-block' : ''
                }`}
              >
                Campo obrigatório
              </div>
            )}
          </div>
        </div>
        <div className="request-race-choose-passenger mb-2">
          <div className="request-race-choose-passenger-option">
            <label className="mb-2" htmlFor="passengerData">
              Passageiro:
            </label>

            <input
              className={`form-control base-input `}
              {...register('favoredName', {
                required: 'Campo obrigatório',
              })}
              onChange={(value) => {
                value && setValue('favoredName',value.target.value);
              }}
              type="text"
              placeholder="Nome do passageiro"
              name="applicantName"
              required={true}
            />
            <div className="invalid-feedback d-block">
              {errors.favoredName?.message}
            </div>
          </div>
        </div>

        <div className="mb-2 row">
          <div className="col-lg">
            <label className="mb-2 col-lg">Insira a data:</label>
            <input
              {...register('scheduleDate', {
                required: 'Campo obrigatório',
              })}
              type="date"
              min={formattedCurrentDate}
              className={`form-control base-input ${
                errors.scheduleDate ? 'is-invalid' : ''
              }`}
              placeholder="Data da utilização"
              name="scheduleDate"
            />
            <div className="invalid-feedback d-block">
              {errors.scheduleDate?.message}
            </div>
          </div>

          <div className="col-lg">
            <label className="mb-2 col-lg">Insira o horário:</label>
            <div className="col-lg">
              <input
                {...register('scheduleTime', {
                  required: 'Campo obrigatório',
                })}
                type="time"
                className={`form-control base-input ${
                  errors.scheduleTime ? 'is-invalid' : ''
                }`}
                placeholder="Horário da utilização"
                name="scheduleTime"
              />
              <div className="invalid-feedback d-block">
                {errors.scheduleTime?.message}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <label className="mb-2" htmlFor="passengerData">
            Local de origem:
          </label>

          <div className="request-race-choose-passenger-option">
            <Select
              options={stateOptions}
              classNamePrefix="singup-select"
              placeholder="Selecione o estado de origem"
              getOptionLabel={(state: StateCategory) => `${state.nome}`}
              getOptionValue={(state: StateCategory) => String(state.id)}
              onChange={(value) => {
                value && setSelectedState(value);
              }}
              value={selectedState}
              isSearchable
            />
          </div>

          <div className="mt-2">
            <Controller
              name="originCity"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  classNamePrefix="singup-select"
                  placeholder="Selecione a cidade de origem"
                  noOptionsMessage={() => 'Selecione um estado de origem'}
                  options={citiesOptions}
                  getOptionLabel={(city: CityCategory) => `${city.nome}`}
                  getOptionValue={(city: CityCategory) => String(city.id)}
                  onChange={(city) => {
                    if (city?.nome) {
                      setSelectedOriginCity(city);
                      setValue('originCity', city.nome);
                    }
                  }}
                  value={selectedOriginCity}
                  inputId="origemState"
                  isSearchable
                />
              )}
            />
            {errors.originCity && (
              <div
                className={`${
                  errors.originCity ? 'invalid-feedback d-block' : ''
                }`}
              >
                Campo obrigatório
              </div>
            )}
          </div>

          <div>
            <input
              {...register('originAddress', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input mt-2 ${
                errors.originAddress ? 'is-invalid' : ''
              }`}
              placeholder="Endereço de origem"
              name="originAddress"
            />
            <div className="invalid-feedback d-block">
              {errors.originAddress?.message}
            </div>
          </div>
        </div>

        <div className="row">
          <label className="mb-2 mt-2" htmlFor="passengerData">
            Local de destino:
          </label>

          <div className="request-race-choose-passenger-option">
            <Select
              options={stateDestinyOptions}
              classNamePrefix="singup-select"
              placeholder="Selecione o estado de destino"
              getOptionLabel={(state: StateCategory) => `${state.nome}`}
              getOptionValue={(state: StateCategory) => String(state.id)}
              onChange={(value) => {
                value && setSelectedDestinyState(value);
              }}
              value={selectedDestinyState}
              isSearchable
            />
          </div>

          <div className="mt-2">
            <Controller
              name="destinyCity"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  classNamePrefix="singup-select"
                  placeholder="Selecione a cidade de destino"
                  noOptionsMessage={() => 'Selecione um estado de destino'}
                  options={citiesOptions}
                  getOptionLabel={(city: CityCategory) => `${city.nome}`}
                  getOptionValue={(city: CityCategory) => String(city.id)}
                  onChange={(city) => {
                    if (city?.nome) {
                      setSelectedDestinyCity(city);
                      setValue('destinyCity', city.nome);
                    }
                  }}
                  value={selectedDestinyCity}
                  inputId="origemState"
                  isSearchable
                />
              )}
            />
            {errors.destinyCity && (
              <div
                className={`${
                  errors.destinyCity ? 'invalid-feedback d-block' : ''
                }`}
              >
                Campo obrigatório
              </div>
            )}
          </div>

          <div className="col-lg mb-2 mt-2">
            <input
              {...register('destinyAddress', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.destinyAddress ? 'is-invalid' : ''
              }`}
              placeholder="Endereço de destino"
              name="destinyAddress"
            />
            <div className="invalid-feedback d-block">
              {errors.destinyAddress?.message}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg mb-2">
            <input
              {...register('costCenter', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.costCenter ? 'is-invalid' : ''
              }`}
              placeholder="Centro de custo"
              name="costCenter"
            />
            <div className="invalid-feedback d-block">
              {errors.costCenter?.message}
            </div>
          </div>

          <div className="col-lg mb-2">
            <input
              {...register('contact', {
                required: 'Campo obrigatório',
                minLength: {
                  value: 11,
                  message:
                    'O número deve incluir o DDD no início ex: (11) XXXX-XXXX ',
                },
              })}
              type="text"
              className={`form-control base-input ${
                errors.contact ? 'is-invalid' : ''
              }`}
              maxLength={15}
              placeholder="Contato"
              name="contact"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            <div className="invalid-feedback d-block">
              {errors.contact?.message}
            </div>
          </div>
        </div>

        <div className="mb-4">
          <input
            {...register('observations')}
            type="text"
            className={`form-control base-input ${
              errors.observations ? 'is-invalid' : ''
            }`}
            placeholder="Observações"
            name="observations"
          />
          <div className="invalid-feedback d-block">
            {errors.observations?.message}
          </div>
        </div>

        {isLoading ? (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            visible={true}
            wrapperClass="login-loading"
          />
        ) : (
          <ButtonIcon text="Solicitar corrida" />
        )}
      </form>
    </div>
  );
};
export default RequestRideOperator;
