import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SwalAlertError } from 'utils/SwalAlert/SwalAlert';
import { RideDateFormatter } from 'utils/formatters/formatterResponse';
import {
  requestBackendRidesByEntity,
  requestBackendRidesByEntityUsingFilter,
} from 'utils/requests/ride/rideRequests';

import { SpringPage } from 'utils/types/request.types';
import { EntitiesType } from 'utils/types/entities.types';
import { AllRidesResponse } from 'utils/requests/ride/rideRequests.types';

import Pagination from 'components/Pagination';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';
import { hasAnyRoles } from 'utils/requests/auth/auth';
import { BiPlus } from 'react-icons/bi';
import { LuEqual } from 'react-icons/lu';
import DriverFilter from './filters/components/DriverFilter';
import DriverFilterData from './filters/types/DriverFilterData';
import CompanyFilterData from './filters/types/CompanyFilterData';
import AdminFilterData from './filters/types/AdminFilterData';
import FilterCompany from './filters/components/FilterCompany';
import AdminFilter from './filters/components/AdminFilter';

type ControlComponentsData = {
  activePage: number;
};

type Props = {
  requestEntity: EntitiesType;
};

const RideHistory = ({ requestEntity }: Props) => {
  const handleRequestDataWithFilter = async (
    data: DriverFilterData | CompanyFilterData | AdminFilterData,
  ) => {
    setIsLoading(true);
    try {
      const response = await requestBackendRidesByEntityUsingFilter(
        controlComponentsData.activePage,
        requestEntity,
        data,
      );
      setPage(response.data);
    } catch {
      SwalAlertError(errorText).then(() => {
        history.push('/myapp/menu');
      });
    } finally {
      setIsLoading(false);
    }
  };

  const history = useHistory();
  const [page, setPage] = useState<SpringPage<AllRidesResponse>>();
  const [isLoading, setIsLoading] = useState<boolean>(false); // Inicializado como false
  const [controlComponentsData, setControlComponentsData] =
    useState<ControlComponentsData>({ activePage: 0 });

  const errorText = useMemo(
    () => ({
      title: 'Oops',
      content: 'Erro ao fazer trazer corridas',
    }),
    [],
  );

  const handlePageChange = (pageNumber: number) => {
    setControlComponentsData({ activePage: pageNumber });
  };

  const getRaces = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await requestBackendRidesByEntity(
        controlComponentsData.activePage,
        requestEntity,
      );
      setPage(response.data);
    } catch {
      SwalAlertError(errorText).then(() => {
        history.push('/myapp/menu');
      });
    } finally {
      setIsLoading(false); // Finaliza o loading
    }
  }, [controlComponentsData.activePage, errorText, history, requestEntity]); // Dependência apenas na página ativa

  const onClickRideItem = (rideId?: number) => {
    history.push(`/myapp/menu/ride-history/${rideId}`);
  };

  useEffect(() => {
    getRaces();
  }, [getRaces]);

  return (
    <div className="base-card p-5 mt-5">
      <div className="container">
        <h1 className="page-title">Histórico de corridas</h1>
        {hasAnyRoles(['ROLE_ADMIN']) && (
          <>
            <div className="btn-group d-flex flex-wrap  mb-4 ">
              <button className="btn btn-outline-success mt-3">
                Despesas: R${' '}
                {page?.content.reduce(
                  (accumulator, item) => accumulator + item.ride.totalExpenses,
                  0,
                )}
              </button>
              <button className="btn btn-outline-success mt-3">
                <BiPlus />
              </button>
              <button className="btn btn-outline-success mt-3 ">
                Lucro motorista: R${' '}
                {page?.content.reduce(
                  (accumulator, item) =>
                    accumulator + item.ride.driverFeeWithoutExpense,
                  0,
                )}
              </button>
              <button className="btn btn-outline-success mt-3 ">
                Corrida: R${' '}
                {page?.content.reduce(
                  (accumulator, item) => accumulator + item.ride.ridePrice,
                  0,
                )}
              </button>
              <button className="btn btn-outline-success mt-3">
                <LuEqual />
              </button>
              <button className="btn btn-outline-success mt-3 ">
                Total: R${' '}
                {page?.content.reduce(
                  (accumulator, item) => accumulator + item.ride.totalClient,
                  0,
                )}
              </button>
            </div>
            <AdminFilter onSubmitFilter={handleRequestDataWithFilter} />
          </>
        )}

        {hasAnyRoles(['ROLE_COMPANY']) && (
          <>
            <FilterCompany onSubmitFilter={handleRequestDataWithFilter} />
            <div className="btn-group  mb-4 ">
              <button className="btn btn-outline-success mt-3">
                Despesas: R${' '}
                {page?.content.reduce(
                  (accumulator, item) => accumulator + item.ride.totalExpenses,
                  0,
                )}
              </button>
              <button className="btn btn-outline-success mt-3">
                <BiPlus />
              </button>
              <button className="btn btn-outline-success mt-3 ">
                Corrida: R${' '}
                {page?.content.reduce(
                  (accumulator, item) => accumulator + item.ride.ridePrice,
                  0,
                )}
              </button>
              <button className="btn btn-outline-success mt-3">
                <LuEqual />
              </button>
              <button className="btn btn-outline-success mt-3 ">
                Total: R${' '}
                {page?.content.reduce(
                  (accumulator, item) => accumulator + item.ride.totalClient,
                  0,
                )}
              </button>
            </div>
          </>
        )}

        {hasAnyRoles(['ROLE_DRIVER']) && (
          <>
            <DriverFilter onSubmitFilter={handleRequestDataWithFilter} />
            <div className="btn-group  mb-4">
              <button className="btn btn-outline-success mt-3 ">
                Despesas R${' '}
                {page?.content.reduce(
                  (accumulator, item) => accumulator + item.ride.totalExpenses,
                  0,
                )}
              </button>
              <button className="btn btn-outline-success mt-3">
                <BiPlus />
              </button>
              <button className="btn btn-outline-success mt-3 ">
                Corrida R${' '}
                {page?.content.reduce(
                  (accumulator, item) =>
                    accumulator + item.ride.driverFeeWithoutExpense,
                  0,
                )}
              </button>
              <button className="btn btn-outline-success mt-3">
                <LuEqual />
              </button>
              <button className="btn btn-outline-success mt-3 ">
                Total R${' '}
                {page?.content.reduce(
                  (accumulator, item) => accumulator + item.ride.fee,
                  0,
                )}
              </button>
            </div>
          </>
        )}
        {isLoading ? (
          <ThreeDotsLoader />
        ) : page?.content.length ? (
          <div className="overflow">
            <table className="table-container">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>C.Custo</th>
                  <th>DATA</th>
                  <th>HORÁRIO</th>
                  <th>FAVORECIDO</th>
                  <th>MOTORISTA</th>
                  <th>ORIGEM</th>
                  <th>DESTINO</th>
                  <th>EMPRESA</th>

                  {hasAnyRoles(['ROLE_OPERATOR']) && <th>VALOR</th>}
                  {hasAnyRoles(['ROLE_DRIVER', 'ROLE_ADMIN']) && (
                    <>
                      <th>DESPESAS</th>
                      <th>CORRIDA</th>
                      <th>TOTAL MOTORISTA</th>
                    </>
                  )}
                  {hasAnyRoles(['ROLE_COMPANY']) && (
                    <>
                      <th>DESPESAS</th>
                      <th>CORRIDA</th>
                      <th>TOTAL</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {page.content.map((ride) => (
                  <tr
                    key={ride.ride.id}
                    className="table-animation-effect"
                    onClick={() => onClickRideItem(ride.ride.id)}
                  >
                    <td>{`#${ride.ride.externalRideId}`}</td>
                    <td>{`${ride.ride.costCenter}`}</td>
                    <td>{RideDateFormatter(ride.ride.scheduleDate)}</td>
                    <td>{ride.ride.scheduleTime}</td>
                    <td>
                      {ride.ride.favoredName ??
                        ride.passenger?.lastName +
                          ' ' +
                          ride?.passenger?.lastName}
                    </td>
                    <td>
                      {ride.car?.driver
                        ? ride.car.driver.firstName +
                          ' ' +
                          ride.car.driver.lastName
                        : 'Não alocado'}
                    </td>
                    <td>{ride.ride.originCity}</td>
                    <td>{ride.ride.destinyCity}</td>
                    <td>{ride.passenger.company.name}</td>
                    {hasAnyRoles(['ROLE_DRIVER', 'ROLE_ADMIN']) && (
                      <>
                        <td>{ride.ride.totalExpenses}</td>
                        <td>{ride.ride.driverFeeWithoutExpense}</td>
                        <td>{Number(ride.ride.fee)}</td>
                      </>
                    )}
                    {hasAnyRoles(['ROLE_OPERATOR']) && (
                      <td>{ride.ride.driverFeeWithoutExpense}</td>
                    )}
                    {hasAnyRoles(['ROLE_COMPANY']) && (
                      <>
                        <td>{ride.ride.totalExpenses}</td>
                        <td>{ride.ride.ridePrice}</td>
                        <th>{ride.ride.totalClient}</th>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <h3 className="page-subtitle">Não há histórico de corridas</h3>
        )}

        {page && page?.content.length > 0 && (
          <div className="mt-5">
            <Pagination
              pageCount={page.totalPages}
              pageRange={3}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RideHistory;
