import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';

import Swal from 'sweetalert2';

import { requestSaveExpense } from 'utils/requests/expenses/expensesRequest';
import { SaveExpensePayload } from 'utils/requests/expenses/expensesRequests.types';

import './styles.css';

type SelectOption = {
  value: string;
  label: string;
};

type FormData = {
  expenseType: SelectOption;
  expenseCost: number;
};

const expanseOptions: SelectOption[] = [
  { value: '1', label: 'Hora parada' },
  { value: '2', label: 'Alimentação' },
  { value: '3', label: 'Estacionamento' },
  { value: '4', label: 'Pedágio' },
  { value: '5', label: 'Hotel' },
  { value: '6', label: 'Combustível' },
  { value: '7', label: 'Outro' },
];

type Props = {
  rideId: number;
};

const RideExpense = ({ rideId }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    const saveExpensePayload: SaveExpensePayload = {
      expense: formData.expenseType.value,
      spend: String(formData.expenseCost).replace(',', '.'),
      ride: {
        id: String(rideId),
      },
    };

    requestSaveExpense(saveExpensePayload)
      .then(() => {
        Swal.close();
        Swal.fire('Custo inserido com sucesso', '', 'success');
      })
      .catch(() => {
        Swal.fire('Oops houve um problema', '', 'error');
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <Controller
          name="expenseType"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              classNamePrefix={`${
                errors.expenseCost ? 'require-expense-type' : ''
              }`}
              options={expanseOptions}
              placeholder="Selecione um tipo de custo"
              noOptionsMessage={() => 'Opção não encontrada'}
              getOptionLabel={(expense: SelectOption) => expense.label}
              getOptionValue={(expense: SelectOption) => expense.value}
            />
          )}
        />
        {errors.expenseType && (
          <div className="text-start invalid-feedback d-block">
            Campo obrigatório
          </div>
        )}
      </div>

      <div className="mb-4">
        <Controller
          name="expenseCost"
          rules={{ required: 'Campo obrigatório' }}
          control={control}
          render={({ field }) => (
            <CurrencyInput
              placeholder="Custo R$"
              className={`form-control base-input ${
                errors.expenseCost ? 'is-invalid' : ''
              }`}
              disableGroupSeparators={true}
              value={field.value}
              onValueChange={field.onChange}
            />
          )}
        />
        <div className="text-start invalid-feedback d-block">
          {errors.expenseCost?.message}
        </div>
      </div>

      <div className="ride-expense-btn-container">
        <button className="btn btn-tertiary ride-expense-btn" type="submit">
          Salvar
        </button>
        <button
          className="btn btn-danger ride-expense-btn"
          type="button"
          onClick={() => Swal.close()}
        >
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default RideExpense;
