import { SingupDataForm } from 'pages/MyApp/Auth/Singup';

import { RequestRideFormData } from 'components/RequestRide/RequestRide.types';

import { getCompanyIdData } from 'utils/storage';
import { SingupData } from 'utils/types/request.types';
import { EntitiesType, RideId, SaveInvoice } from 'utils/types/entities.types';
import {
  AllRidesResponse,
  RequestBackendRaceData,
} from 'utils/requests/ride/rideRequests.types';

export const singupUserFormatter = (
  singupData: SingupDataForm,
  requestEntity: EntitiesType,
) => {
  const entitiesRoles = {
    ADMIN: 1,
    DRIVER: 2,
    MANAGER: 3,
    PASSENGER: 4,
    OPERATOR: 5,
  };
  const entityRole = entitiesRoles[requestEntity];
  const companyId = getCompanyIdData();

  let payload: SingupData = {
    ...singupData,
    roles: [{ id: entityRole }],
  };

  if (entityRole === 3 || entityRole === 4) {
    payload = {
      ...payload,
      company: { id: companyId },
    };
  }

  if (entityRole === 2) {
    payload = {
      ...payload,
      kmPrice: String(singupData.kmPrice).replace(',', '.'),
    };
  }

  return payload;
};

export const requestRaceFormatter = (raceData: RequestRideFormData) => {
  const companyId = getCompanyIdData();
  const formattedScheduleTime = `${raceData.scheduleTime}:00`;

  const requestRaceObj: RequestBackendRaceData = {
    ride: { ...raceData, scheduleTime: formattedScheduleTime },
    company: { id: companyId },
    passenger: {
      id:
        raceData.passengerData && raceData.passengerData.id
          ? raceData.passengerData.id
          : 0,
    },
  };

  return requestRaceObj;
};

export const requestInvoiceFormater = (rides: AllRidesResponse[]) => {
  const rideIdPayload: RideId[] = [];

  rides.map((ride) => {
    const rideId: RideId = { ride: { id: ride.ride.id! } };
    return rideIdPayload.push(rideId);
  });

  const saveInvoicePayload: SaveInvoice = {
    company: {
      id: rides[0].passenger.company.id!,
    },
    rides: rideIdPayload,
  };

  return saveInvoicePayload;
};

export const requestChangeRideValuesFormater = (rides: AllRidesResponse[]) => {
  const rideIdPayload: RideId[] = [];

  rides.map((ride) => {
    const rideId: RideId = { ride: { id: ride.ride.id! } };
    return rideIdPayload.push(rideId);
  });

  return rideIdPayload;
};
