import './styles.css';

interface VehiclesCardProps {
  name: string;
  description: string;
  imageUrl: any;
}

const VehiclesCard = (props: VehiclesCardProps) => {
  const { name, description, imageUrl } = props;

  return (
    <div className="vehicles-card-container">
      <div className="vehicle-image-container">
        <img src={imageUrl} alt={name} />
      </div>

      <div className="vehicle-text-container">
        <h1>{name}</h1>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default VehiclesCard;
