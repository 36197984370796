import axios from 'axios';

import { getAuthData } from 'utils/storage';
import { BASE_URL } from 'utils/requests/requests';

import {
  SaveDriverFeePayload,
  SaveExpensePayload,
} from './expensesRequests.types';

export const requestSaveExpense = (payload: SaveExpensePayload) => {
  const token = getAuthData().access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  const data = JSON.stringify(payload);

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/expenses',
    data,
    headers,
  });
};

export const requestSaveDriverFee = (payload: SaveDriverFeePayload) => {
  const token = getAuthData().access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  const data = JSON.stringify(payload);

  return axios({
    method: 'POST',
    baseURL: BASE_URL,
    url: '/fees',
    data,
    headers,
  });
};

export const requestFinishRideInvoice = (rideId: string) => {
  const token = getAuthData().access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'PUT',
    baseURL: BASE_URL,
    url: `/rides/${rideId}/faturated`,
    headers,
  });
};

export const requestRemoveDriverFromRide = (rideId: string) => {
  const token = getAuthData().access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'DELETE',
    baseURL: BASE_URL,
    url: `/rides/${rideId}/driver`,
    headers,
  });
};

export const requestDeleteExpense = (expenseId: number) => {
  const token = getAuthData().access_token;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer' + token,
  };

  return axios({
    method: 'DELETE',
    baseURL: BASE_URL,
    url: `/expenses/${expenseId}`,
    headers,
  });
};
