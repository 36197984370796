import { useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useForm } from 'react-hook-form';
import { ThreeDots } from 'react-loader-spinner';
import { toast } from 'react-toastify';

import ButtonIcon from 'components/Buttons/ButtonIcon';

import { getAuthData } from 'utils/storage';
import { requestBackend } from 'utils/requests/requests';
import { CompanyUser } from 'utils/requests/company/companyRequests.types';
import {
  extractNumbers,
  formatPhoneNumber,
  maskToPhoneNumber,
} from 'utils/formatters/fomatterInput';

export type UpdateUserData = {
  firstName: string;
  lastName: string;
  email: string;
  costCenter?: string;
  phoneNumber?: string;
};

const UpdateAccount = () => {
  const [hasError, setHasError] = useState(false);
  const [isLoging, setIsLoging] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateUserData>();

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhone);
    setValue('phoneNumber', formattedPhone);
  };

  const onSubmit = (formData: UpdateUserData) => {
    const userId = getAuthData().userId;

    const formmatedFormData: UpdateUserData = {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      costCenter: formData.costCenter,
      phoneNumber: extractNumbers(formData.phoneNumber ?? ''),
    };

    const config: AxiosRequestConfig = {
      method: 'PUT',
      url: `users/${userId}`,
      withCredentials: true,
      data: formmatedFormData,
    };

    requestBackend(config)
      .then(() => {
        toast.success('Informações atualizadas');
      })
      .catch(() => {
        toast.error('Falha ao atualizar informações');
      });
  };

  useEffect(() => {
    const userId = getAuthData().userId;

    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `users/${userId}`,
      withCredentials: true,
    };

    requestBackend(config)
      .then((response) => {
        const user = response.data as CompanyUser;
        const maskedPhoneNumber = maskToPhoneNumber(user.phoneNumber);

        setValue('firstName', user.firstName);
        setValue('lastName', user.lastName);
        setValue('email', user.email);
        setValue('costCenter', user.costCenter);
        setValue('phoneNumber', maskedPhoneNumber);
        setPhoneNumber(maskedPhoneNumber);
      })
      .catch(() => {
        setHasError(true);
        setIsLoging(false);
      });
  }, [setValue]);

  return (
    <div className="container">
      <div className="base-card login-card mt-5">
        <h1>Atualizar informações da conta </h1>

        {hasError && (
          <div className="alert alert-danger">
            Erro ao tentar cadastrar usuário
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <input
              {...register('firstName', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.firstName ? 'is-invalid' : ''
              }`}
              placeholder="Nome"
              name="firstName"
              readOnly
            />
            <div className="invalid-feedback d-block">
              {errors.firstName?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('lastName', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.lastName ? 'is-invalid' : ''
              }`}
              placeholder="Sobrenome"
              name="lastName"
              readOnly
            />
            <div className="invalid-feedback d-block">
              {errors.lastName?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('phoneNumber', {
                required: 'Campo obrigatório',
                minLength: {
                  value: 11,
                  message: 'O número de telefone deve conter 11 dígitos',
                },
              })}
              type="text"
              className={`form-control base-input ${
                errors.email ? 'is-invalid' : ''
              }`}
              placeholder="Contato"
              name="celphone"
              maxLength={15}
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            <div className="invalid-feedback d-block">
              {errors.phoneNumber?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('email', {
                required: 'Campo obrigatório',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Email inválido',
                },
              })}
              type="email"
              className={`form-control base-input ${
                errors.email ? 'is-invalid' : ''
              }`}
              placeholder="Email"
              name="email"
              readOnly
            />
            <div className="invalid-feedback d-block">
              {errors.email?.message}
            </div>
          </div>

          <div className="mb-4">
            <input
              {...register('costCenter', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.costCenter ? 'is-invalid' : ''
              }`}
              placeholder="Centro de custo"
              name="costCenter"
            />
            <div className="invalid-feedback d-block">
              {errors.costCenter?.message}
            </div>
          </div>

          <div className="login-submit mb-5">
            {isLoging ? (
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                visible={true}
                wrapperClass="login-loading"
              />
            ) : (
              <ButtonIcon text="Atualizar informações" />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateAccount;
