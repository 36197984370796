import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// THIRD PARTY LIBS
import { AxiosRequestConfig } from 'axios';
import Swal from 'sweetalert2';

// UTILS
import { requestBackend } from 'utils/requests/requests';
import { Passenger } from 'utils/types/entities.types';

// COMPONENTS
import ItemDetails from 'components/ItemDetails';
import ButtonRegular from 'components/Buttons/ButtonRegular';

type UrlParams = {
  operatorId: string;
};

const OperatorDetails = () => {
  const history = useHistory();
  const [operator, setOperator] = useState<Passenger>();
  const [error, setError] = useState<boolean>(false);
  const { operatorId } = useParams<UrlParams>();

  useEffect(() => {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `users/${operatorId}`,
      withCredentials: true,
    };

    requestBackend(config)
      .then((response) => {
        setOperator(response.data);
      })
      .catch(() => {
        setError(true);
      });
  }, [operatorId]);

  const deleteDriver = () => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#dc3545',
      title: 'Deseja realmente deletar este operador?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const config: AxiosRequestConfig = {
          method: 'DELETE',
          url: `users/${operatorId}`,
          withCredentials: true,
        };

        requestBackend(config)
          .then(() => {
            Swal.fire('Operador deletado', 'Sucesso', 'success').then(() => {
              history.goBack();
            });
          })
          .catch(() => {
            Swal.fire('Oops!', 'Erro ao tentar deletar operador', 'error');
          });
      } else if (result.isDenied) {
        Swal.fire('Deleção não efetuada', '', 'info');
      }
    });
  };

  return (
    <div className="race-details-container p-3 mt-5 base-card">
      <div className="race-details-solicitation-card p-3 container">
        {error ? (
          <div className="alert alert-danger">
            Erro ao tentar trazer informações do operador
          </div>
        ) : (
          <>
            {operator && (
              <>
                <h1 className="details-card-title">Sobre o operador</h1>
                <ItemDetails
                  itemData={`${operator.firstName} ${operator.lastName}`}
                  itemTitle="Nome"
                />

                {operator.phoneNumber && (
                  <ItemDetails
                    itemData={operator.phoneNumber}
                    itemTitle="Telefone"
                  />
                )}

                <ItemDetails itemData={operator.email} itemTitle="Email" />
              </>
            )}
          </>
        )}
        <div className="text-end mt-4">
          <ButtonRegular
            text="Deletar"
            className="btn btn-danger me-2"
            onClick={deleteDriver}
          />
        </div>
      </div>
    </div>
  );
};

export default OperatorDetails;
