import { Controller, useForm } from 'react-hook-form';
import '../style.css';
import Select from 'react-select';
import {
  CompanyDataResponse,
  CompanyUser,
} from '../../../../utils/requests/company/companyRequests.types';
import { useEffect, useState } from 'react';
import {
  requestAllPassengers,
  requestBackEndAllCompanies,
} from '../../../../utils/requests/company/companyRequests';
import Swal from 'sweetalert2';
import { RequestAllDriversResponse } from '../../../../utils/requests/driver/driverRequests.types';
import { AxiosRequestConfig } from 'axios';
import { requestBackend } from '../../../../utils/requests/requests';
import AdminFilterData from '../types/AdminFilterData';
import { DriverData } from '../types/DriverData';

type Props = {
  onSubmitFilter: (data: AdminFilterData) => void;
};

const AdminFilter = ({ onSubmitFilter }: Props) => {
  const { register, handleSubmit, setValue, control } =
    useForm<AdminFilterData>();
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([]);
  const [passengerOptions, setPassengerOptions] = useState<
    { id: number; name: string }[]
  >([]);
  const [companies, setCompanies] = useState<CompanyDataResponse[]>([]);
  const [selectedPassenger, setSelectedPassenger] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [selectedDriver, setSelectedDriver] = useState<DriverData | null>(null);
  const [selectedCompany, setSelectedCompany] =
    useState<CompanyDataResponse | null>(null);
  const [allDrivers, setAllDrivers] = useState<RequestAllDriversResponse[]>([]);
  const [formattedDriver, setFormattedDriver] = useState<DriverData[]>([]);

  const handleFormClear = () => {
    setValue('startDate', null);
    setValue('endDate', null);
    setValue('costCenter', null);
    setValue('companyId', null);
    setValue('driverId', null);
    setValue('passengerId', null);
    setSelectedPassenger(null);
    setSelectedDriver(null);
    setSelectedCompany(null);
  };

  const handleChangeCategory = (value: CompanyDataResponse | null) => {
    setValue('companyId', value ? value.id : null);
    setSelectedCompany(value);
  };

  useEffect(() => {
    requestAllPassengers().then((response) => {
      setCompanyUsers(response.data.content);
    });
  }, []);

  useEffect(() => {
    if (companyUsers) {
      const userList = companyUsers.map((user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
      }));
      setPassengerOptions(userList);
    }
  }, [companyUsers]);

  useEffect(() => {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: '/users/driver',
      withCredentials: true,
    };

    requestBackend(config)
      .then((response) => {
        setAllDrivers(response.data.content);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  useEffect(() => {
    if (allDrivers?.length) {
      const formattedDriverArray = allDrivers.map((driver) => ({
        driverId: driver.driver.id,
        driverName: `${driver.driver.firstName} ${driver.driver.lastName}`,
      }));
      setFormattedDriver(formattedDriverArray);
    }
  }, [allDrivers]);

  useEffect(() => {
    requestBackEndAllCompanies()
      .then((response) => {
        setCompanies(response.data.content);
      })
      .catch(() => {
        Swal.fire({ title: 'Erro ao buscar empresas', icon: 'error' });
      });
  }, []);

  const onSubmit = (formData: AdminFilterData) => {
    const payload: AdminFilterData = {
      startDate: formData.startDate ? formData.startDate : null,
      endDate: formData.endDate ? formData.endDate : null,
      passengerId: selectedPassenger ? selectedPassenger.id : null,
      costCenter: formData.costCenter ? formData.costCenter : null,
      companyId: formData.companyId ? formData.companyId : null,
      driverId: selectedDriver ? selectedDriver.driverId : null,
    };

    onSubmitFilter(payload);
  };

  return (
    <div className="invoice-filter-container">
      <form onSubmit={handleSubmit(onSubmit)} className="invoice-filter-form">
        <div className="invoice-filter-city-container">
          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="driverId">
              Motorista
            </label>
            <Controller
              name="driverId"
              control={control}
              render={({ field }) => (
                <Select
                  isClearable
                  {...field}
                  options={formattedDriver}
                  onChange={(value) => {
                    setSelectedDriver(value as DriverData);
                    field.onChange(value?.driverId ?? null); // Corrigido para passar driverId
                  }}
                  getOptionLabel={(driver: DriverData) =>
                    driver.driverName || ''
                  }
                  getOptionValue={(driver: DriverData) =>
                    String(driver.driverId)
                  }
                  placeholder="Selecione o motorista"
                  noOptionsMessage={() => 'Sem opções'}
                  value={selectedDriver}
                />
              )}
            />
          </div>

          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="companyId">
              Empresa:
            </label>
            <Controller
              name="companyId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={companies}
                  isClearable
                  value={selectedCompany}
                  placeholder="Empresa"
                  noOptionsMessage={() => 'Sem opções'}
                  onChange={(value) =>
                    handleChangeCategory(value as CompanyDataResponse)
                  }
                  getOptionLabel={(category: CompanyDataResponse) =>
                    category.name
                  }
                  getOptionValue={(category: CompanyDataResponse) =>
                    String(category.id)
                  }
                />
              )}
            />
          </div>

          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="passengerId">
              Passageiro:
            </label>
            <Controller
              name="passengerId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={passengerOptions}
                  isClearable
                  placeholder="Passageiro"
                  noOptionsMessage={() => 'Sem opções'}
                  getOptionLabel={(passenger) => passenger.name}
                  getOptionValue={(passenger) => String(passenger.id)}
                  onChange={(value) => {
                    setSelectedPassenger(value);
                    field.onChange(value?.id ?? null); // Corrigido para passar id
                  }}
                  value={selectedPassenger}
                  inputId="passengerId"
                />
              )}
            />
          </div>

          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="costCenter">
              Centro de custo
            </label>
            <input
              {...register('costCenter')}
              type="text"
              className="form-control"
              placeholder="Centro de custo"
              name="costCenter"
            />
          </div>
        </div>

        <div className="invoice-filter-city-container">
          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="startDate">
              Início do período:
            </label>
            <input
              {...register('startDate')}
              type="date"
              className="form-control"
              placeholder="Data inicio"
              name="startDate"
            />
          </div>

          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="endDate">
              Fim do período:
            </label>
            <input
              {...register('endDate')}
              type="date"
              className="form-control"
              placeholder="Data fim"
              name="endDate"
            />
          </div>
        </div>

        <div className="invoice-filter-btn-container">
          <button
            type="button"
            onClick={handleFormClear}
            className="btn btn-outline-secondary"
          >
            LIMPAR
          </button>

          <button type="submit" className="btn btn-secondary">
            FILTRAR
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminFilter;
