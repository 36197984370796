import { NavLink } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import { BsBuilding } from 'react-icons/bs';
import { AiOutlineCar } from 'react-icons/ai';
import { MdHistory, MdPendingActions } from 'react-icons/md';
import { BiCar } from 'react-icons/bi';

const AdminNavBar = () => {
  return (
    <>
      <NavLink
        to="/myApp/menu/admin/new-ride"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <BiCar />
        <h3 className="my-app-nav-option">Nova corrida</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/admin/pending-rides"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <MdPendingActions />
        <h3 className="my-app-nav-option">Corridas pendentes</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/ride-history"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <MdHistory />
        <h3 className="my-app-nav-option">Histórico de corridas</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/admin/my-drivers"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <AiOutlineCar />
        <h3 className="my-app-nav-option">Motoristas</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/admin/my-companies"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <BsBuilding />
        <h3 className="my-app-nav-option">Empresas</h3>
      </NavLink>

      <NavLink
        to="/myApp/menu/admin/my-operators"
        className="navigation-item"
        activeClassName="active-navigation-item"
      >
        <CgProfile />
        <h3 className="my-app-nav-option">Operadores</h3>
      </NavLink>
      {/*
            Módulo desativado por opção do cliente
            <NavLink
                to="/myApp/menu/admin/invoice"
                className="navigation-item"
                activeClassName="active-navigation-item"
            >
            <BiDollar />
            <h3 className="my-app-nav-option">Fechamentos</h3>
          </NavLink>
            */}
    </>
  );
};

export default AdminNavBar;
