import { Link } from 'react-router-dom';

import { ReactComponent as NotAuthenticatedImg } from 'assets/images/not_authenticated_user.svg';

import './styles.css';

const LoginRedirect = () => {
  return (
    <Link to="/login/auth/enter" className="login-redirect-container">
      <p className="login-redirect-link">Clique e faça login no Quality App</p>

      <div className="login-redirect-img">
        <NotAuthenticatedImg />
      </div>
    </Link>
  );
};

export default LoginRedirect;
