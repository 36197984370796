import Swal from 'sweetalert2';
import { SwalAlert } from './SwalAlert.types';

export const SwalAlertError = (props: SwalAlert) => {
  return Swal.fire(`${props.title}`, `${props.content}`, 'error');
};

export const SwalAlertSucess = (props: SwalAlert) => {
  return Swal.fire(`${props.title}`, `${props.content}`, 'success');
};
