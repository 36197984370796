import { useEffect, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';

import history from 'utils/history';
import { getAuthData, removeAuthData } from 'utils/storage';
import { hasAnyRoles, isAuthenticated } from 'utils//requests/auth/auth';
import { getTokenData } from 'utils/requests/auth/auth';
import { redirectRoutes } from 'utils/redirectRoutes';

import { AuthContext } from '../../contex/AuthContext';

import 'bootstrap/js/src/collapse.js';
import './styles.css';
import Swal from 'sweetalert2';

const NavBar = () => {
  const { authContextData, setAuthContextData } = useContext(AuthContext);

  const handleLogoutClick = () => {
    removeAuthData();
    setAuthContextData({
      authenticated: false,
    });
    history.replace('/');
  };

  const deleteDriver = () => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#dc3545',
      title: 'Deseja realmente sair',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogoutClick();
      }
    });
  };

  useEffect(() => {
    if (isAuthenticated()) {
      setAuthContextData({
        authenticated: true,
        tokenData: getTokenData(),
      });
    } else {
      setAuthContextData({ authenticated: false });
    }
  }, [setAuthContextData]);

  useEffect(() => {
    if (isAuthenticated()) {
      hasAnyRoles(['ROLE_DRIVER']) &&
        !getAuthData().hasDriverDocument &&
        history.push('/myapp/menu/driver/dashboard');
    }
  });

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-primary main-nav">
      <div className="container-fluid">
        <Link to="/home" className="nav-logo-text">
          <h4>Quality App</h4>
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#quality-navbar"
          aria-controls="quality-navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon "></span>
        </button>

        <div className="collapse navbar-collapse" id="quality-navbar">
          <ul className="navbar-nav offset-md-2 main-menu">
            <li onClick={() => redirectRoutes()}>
              <NavLink to="/myApp" activeClassName="active">
                MEU APP
              </NavLink>
            </li>

            {authContextData.authenticated && (
              <a
                className="logoutNavLink"
                onClick={deleteDriver}
                href="#logout"
              >
                LOGOUT
              </a>
            )}
          </ul>
        </div>

        <div className="nav-login-logout">
          {authContextData.authenticated ? (
            <>
              <span className="nav-username">
                {authContextData.tokenData?.user_name}
              </span>
              <a href="#logout" onClick={handleLogoutClick}>
                LOGOUT
              </a>
            </>
          ) : (
            <Link to="/login">LOGIN</Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
