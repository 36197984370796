import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import 'swiper/swiper-bundle.min.css';

import VehiclesCard from 'components/VehiclesCard';

import { CarouselVehicles } from 'mock/vehicle_card';

import './styles.css';

SwiperCore.use([Navigation, Pagination, A11y]);

const VehiclesCarousel = () => {
  return (
    <Swiper
      breakpoints={{
        640: {
          width: 640,
          slidesPerView: 1,
        },

        768: {
          width: 768,
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 4,
        },
      }}
      effect="slide"
      spaceBetween={10}
      speed={500}
      loop={true}
      touchRatio={5.5}
      navigation={true}
      pagination={{ clickable: true }}
    >
      {CarouselVehicles.map((vehicles) => (
        <SwiperSlide key={vehicles.id}>
          <VehiclesCard
            description={vehicles.description}
            imageUrl={vehicles.imageUrl}
            name={vehicles.name}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default VehiclesCarousel;
