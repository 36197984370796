export const formatPhoneNumber = (value: string) => {
  let cleaned = value.replace(/\D/g, '');
  if (
    !cleaned.startsWith('55') &&
    (cleaned.length === 10 || cleaned.length === 11 || cleaned.length === 12)
  ) {
    cleaned = '55' + cleaned;
  }
  const match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return value;
};

export const extractNumbers = (phone: string): string => {
  return phone.replace(/\D/g, '');
};

export function maskToPhoneNumber(phoneNumber?: string): string {
  if (!phoneNumber) {
    return '';
  }

  const cleanedNumber = phoneNumber.replace(/\D/g, '');

  if (cleanedNumber.length === 13 || cleanedNumber.length === 12  ) {
    const formattedNumber = `+${cleanedNumber.slice(0, 2)} (${cleanedNumber.slice(2, 4)}) ${cleanedNumber.slice(4, 9)}-${cleanedNumber.slice(9)}`;
    return formattedNumber;
  } else {
    return 'Número inválido';
  }
}
