import { useForm } from 'react-hook-form';

import Swal from 'sweetalert2';

import './styles.css';
import { requestSetRideKm } from 'utils/requests/ride/rideRequests';

type FormData = {
  rideKm: number;
};

type Props = {
  rideId: number;
};

const RideKm = ({ rideId }: Props) => {
  const {
    handleSubmit,
    formState: { errors },

    register,
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    requestSetRideKm(rideId, Number(formData.rideKm))
      .then(() => {
        Swal.close();
        Swal.fire('Km inserido com sucesso', '', 'success');
      })
      .catch(() => {
        Swal.fire({
          text: 'Algo deu errado',
          icon: 'error',
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <input
          {...register('rideKm', {
            required: 'Campo obrigatório',
            pattern: {
              value: /^\+?(0|[1-9]\d*)$/gm,
              message: 'Número inválido',
            },
          })}
          type="text"
          className={`form-control base-input ${
            errors.rideKm ? 'is-invalid' : ''
          }`}
          placeholder="Km percorrido"
          name="rideKm"
        />
        <div className="text-start invalid-feedback d-block">
          {errors.rideKm?.message}
        </div>
      </div>

      <div className="ride-expense-btn-container">
        <button className="btn btn-tertiary ride-expense-btn" type="submit">
          Salvar
        </button>
        <button
          className="btn btn-danger ride-expense-btn"
          type="button"
          onClick={() => Swal.close()}
        >
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default RideKm;
