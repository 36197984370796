import { useState } from 'react';
import { useForm } from 'react-hook-form';

import Swal from 'sweetalert2';

import history from 'utils/history';
import { RequestProcessInvoice } from 'utils/types/request.types';
import { requestProcessInvoice } from 'utils/requests/invoice/invoiceRequests';

type FormData = {
  message: string;
  approved: boolean;
};

type Props = {
  invoiceId: number;
};

const ProcessInvoice = ({ invoiceId }: Props) => {
  const [hasError, setHasError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmitForm = (formData: FormData) => {
    const payload: RequestProcessInvoice = {
      message: formData.message,
      approved: formData.approved,
      invoiceDTO: {
        id: invoiceId,
      },
    };

    requestProcessInvoice(payload)
      .then(() => {
        Swal.fire(
          'Sucesso!',
          'Corrida solicitado com sucesso!',
          'success',
        ).then(() => {
          history.push('/myapp/menu/company/manager-dashboard');
        });
      })
      .catch(() => {
        setHasError(true);
      });
  };

  return (
    <div>
      {hasError && (
        <div className="alert alert-danger">
          Erro ao tentar solicitar corrida
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="mt-3">
          <textarea
            {...register('message')}
            id="message"
            name="message"
            className={`form-control base-input ${
              errors.message ? 'is-invalid' : ''
            }`}
            placeholder="Mensagem opcional"
            rows={3}
            cols={50}
          />
          <div className="invalid-feedback d-block">
            {errors.message?.message}
          </div>
        </div>

        <div className="mb-5">
          <label className="mt-3 me-2">Aprovar fechamento? </label>
          <label className="me-2" htmlFor="approved">
            Sim
          </label>
          <input
            className="me-2"
            type="radio"
            id="approved"
            value={'true'}
            {...register('approved', {
              required: 'Campo obrigatório',
            })}
          />
          <label className="me-2" htmlFor="notApproved">
            Não
          </label>
          <input
            type="radio"
            id="notApproved"
            value={'false'}
            {...register('approved', {
              required: 'Campo obrigatório',
            })}
          />
          <div className="invalid-feedback d-block">
            {errors.approved?.message}
          </div>
        </div>

        <div className="ride-expense-btn-container">
          <button
            className="btn btn-tertiary ride-expense-btn"
            type="submit"
            onClick={() => onSubmitForm}
          >
            Salvar
          </button>
          <button
            className="btn btn-danger ride-expense-btn"
            type="button"
            onClick={() => Swal.close()}
          >
            Cancelar
          </button>
        </div>
      </form>
    </div>
  );
};

export default ProcessInvoice;
