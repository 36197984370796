import { Controller, useForm } from 'react-hook-form';
import '../style.css';
import CompanyFilterData from '../types/CompanyFilterData';
import Select from 'react-select';
import { useState, useEffect } from 'react';
import { CompanyUser } from '../../../../utils/requests/company/companyRequests.types';
import { Passenger } from '../types/Passenger';
import { requestCompanyById } from '../../../../utils/requests/company/companyRequests';

type Props = {
  onSubmitFilter: (data: CompanyFilterData) => void;
};

const FilterCompany = ({ onSubmitFilter }: Props) => {
  const { register, handleSubmit, setValue, control } =
    useForm<CompanyFilterData>();
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([]);
  const [passengerOptions, setPassengerOptions] = useState<Passenger[]>([]);
  const [selectedPassenger, setSelectedPassenger] = useState<Passenger | null>(
    null,
  );
  const handleFormClear = () => {
    setValue('startDate', null);
    setValue('endDate', null);
    setValue('costCenter', null);
    setSelectedPassenger(null);
  };

  useEffect(() => {
    requestCompanyById()
      .then((response) => {
        setCompanyUsers(response.data.user);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    if (companyUsers) {
      const userList: Passenger[] = companyUsers.map((user) => ({
        id: String(user.id),
        name: `${user.firstName} ${user.lastName}`,
      }));
      setPassengerOptions(userList);
    }
  }, [companyUsers]);

  const onSubmit = (formData: CompanyFilterData) => {
    const payload: CompanyFilterData = {
      startDate: formData.startDate || null,
      endDate: formData.endDate || null,
      passengerId: selectedPassenger ? selectedPassenger.id : null,
      costCenter: formData.costCenter,
    };
    onSubmitFilter(payload);
  };

  return (
    <div className="invoice-filter-container">
      <form onSubmit={handleSubmit(onSubmit)} className="invoice-filter-form">
        <div className="invoice-filter-city-container">
          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="startDate">
              Início do período:
            </label>
            <input
              {...register('startDate')}
              id="startDate"
              type="date"
              className="form-control"
              placeholder="Data inicio"
              name="startDate"
            />
          </div>

          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="endDate">
              Fim do período:
            </label>
            <input
              {...register('endDate')}
              id="endDate"
              type="date"
              className="form-control"
              placeholder="Data fim"
              name="endDate"
            />
          </div>

          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="endDate">
              Centro de custo
            </label>
            <input
              {...register('costCenter')}
              type="text"
              className="form-control"
              placeholder="Centro de custo"
              name="costCenter"
            />
          </div>

          <div className={'invoice-filter-city'}>
            <label className="mb-2" htmlFor="endDate">
              Passageiro:
            </label>
            <Controller
              name="passengerId"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={passengerOptions}
                  placeholder="Passageiro"
                  isClearable
                  getOptionLabel={(passenger: Passenger) => passenger.name}
                  getOptionValue={(passenger: Passenger) =>
                    String(passenger.id)
                  }
                  onChange={(value) => {
                    setSelectedPassenger(value as Passenger);
                    field.onChange(value);
                  }}
                  value={selectedPassenger}
                  inputId="passengerId"
                />
              )}
            />
          </div>
        </div>
        <div className="invoice-filter-btn-container">
          <button
            type="button"
            onClick={handleFormClear}
            className="btn btn-outline-secondary"
          >
            LIMPAR
          </button>

          <button type="submit" className="btn btn-secondary ">
            FILTRAR
          </button>
        </div>
      </form>
    </div>
  );
};

export default FilterCompany;
