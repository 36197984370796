import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AxiosRequestConfig } from 'axios';

import { SpringPage } from 'utils/types/request.types';
import { requestBackend } from 'utils/requests/requests';
import { RequestAllDriversResponse } from 'utils/requests/driver/driverRequests.types';

import ListItem from 'components/UserCard';
import Pagination from 'components/Pagination';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import './styles.css';

type ControlComponentsData = {
  activePage: number;
};

const MyDriversAdmin = () => {
  const [page, setPage] = useState<SpringPage<RequestAllDriversResponse>>();
  const [isLoading, setIsloading] = useState<boolean>();

  const [controlComponentsData, setControlComponentsData] =
    useState<ControlComponentsData>({
      activePage: 0,
    });

  const handlePageChange = (pageNumber: number) => {
    setControlComponentsData({ activePage: pageNumber });
  };

  const getDrivers = useCallback(() => {
    const config: AxiosRequestConfig = {
      method: 'GET',
      url: `/users/driver`,
      params: {
        page: controlComponentsData.activePage,
        size: 10,
      },
      withCredentials: true,
    };

    requestBackend(config)
      .then((response) => {
        setPage(response.data);
        setIsloading(false);
      })
      .catch((error) => {
        setIsloading(false);
        console.log('error', error);
      });
  }, [controlComponentsData]);

  useEffect(() => {
    setIsloading(true);
    getDrivers();
  }, [getDrivers]);

  return (
    <div className="base-card p-5 mt-5">
      <div className="container">
        <h1 className="page-title">Motoristas parceiros cadastrados</h1>

        {isLoading ? (
          <ThreeDotsLoader />
        ) : page?.content.length ? (
          <>
            <h3 className="page-subtitle">
              Ao clicar em um motorista parceiro, você gerenciá-lo.
            </h3>

            <div className="mt-3 mb-5">
              {page.content
                .filter(
                  (item) =>
                    item.driver.isActive || item.driver.isActive == null,
                )
                .map((driverData) => (
                  <Link
                    key={driverData.driver.id}
                    to={`/myApp/menu/admin/my-drivers/details/${driverData.driver.id}`}
                  >
                    <ListItem
                      firstName={driverData.driver.firstName}
                      lastName={driverData.driver.lastName}
                    />
                  </Link>
                ))}
            </div>

            <Pagination
              pageCount={page ? page.totalPages : 0}
              pageRange={3}
              onChange={handlePageChange}
            />
          </>
        ) : (
          <h3 className="page-subtitle">
            Ainda não há motorista parceiro cadastrado.
          </h3>
        )}

        <div className="text-end">
          <button className="btn btn-secondary mt-5">
            <Link to="/myApp/menu/admin/my-drivers/register-driver">
              Registre um motorista parceiro
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MyDriversAdmin;
