import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { ThreeDots } from 'react-loader-spinner';
import CurrencyInput from 'react-currency-input-field';

import Swal from 'sweetalert2';

import ButtonIcon from 'components/Buttons/ButtonIcon';

import history from 'utils/history';
import { saveCompanyIdData } from 'utils/storage';
import { EntitiesType } from 'utils/types/entities.types';
import { requestBackendSingup } from 'utils/requests/user/userRequests';
import { singupUserFormatter } from 'utils/formatters/formatterRequest';

import './styles.css';
import {
  extractNumbers,
  formatPhoneNumber,
} from 'utils/formatters/fomatterInput';
import { SingupData } from 'utils/types/request.types';

export type SingupDataForm = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  costCenter?: string;
  faturation?: boolean;
  kmPrice?: string;
  phoneNumber?: string;
};

type SingupProps = {
  isCompanySingUp?: boolean;
  isManagerSingup?: boolean;
  isDriverRegister?: boolean;
  isOperatorSingup?: boolean;
  requestEntity: EntitiesType;
};

type UrlParams = {
  companyName: string;
  companyId: string;
};

const Singup = ({
  isCompanySingUp = false,
  isManagerSingup = false,
  isDriverRegister = false,
  isOperatorSingup = false,
  requestEntity,
}: SingupProps) => {
  const { companyName, companyId } = useParams<UrlParams>();

  const [hasError, setHasError] = useState(false);
  const [isLoging, setIsLoging] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<SingupDataForm>();

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhone);
    setValue('phoneNumber', formattedPhone);
  };

  const onSubmit = (formData: SingupDataForm) => {
    const singupData = singupUserFormatter(formData, requestEntity);
    const formattedPhoneNumber: string = extractNumbers(formData.phoneNumber!);

    const formattedSignupData: SingupData = {
      ...singupData,
      phoneNumber: formattedPhoneNumber,
    };

    console.log(formattedSignupData);

    setIsLoging(true);

    requestBackendSingup(formattedSignupData)
      .then(() => {
        Swal.fire('Sucesso!', 'Cadastro realizado!', 'success').then(() => {
          if (isCompanySingUp) {
            history.push('/myapp/menu/company/my-users');
          } else if (isManagerSingup) {
            history.goBack();
          } else if (isDriverRegister) {
            history.goBack();
          } else if (isOperatorSingup) {
            history.push('/myApp/menu/admin/my-operators');
          }
        });
        setHasError(false);
        setIsLoging(false);
      })
      .catch((error) => {
        setHasError(true);
        setIsLoging(false);
      });
  };

  useEffect(() => {
    isManagerSingup && saveCompanyIdData(Number(companyId));
  }, [isManagerSingup, companyId]);

  return (
    <div className="container">
      <div className="base-card login-card mt-5">
        {isManagerSingup ? (
          <h1>Cadastrar gerente parceiro - {companyName}</h1>
        ) : isCompanySingUp ? (
          <h1>Cadastrar passageiro</h1>
        ) : isOperatorSingup ? (
          <h1>Cadastrar operador Quality</h1>
        ) : (
          <h1>Cadastrar motorista parceiro</h1>
        )}
        {hasError && (
          <div className="alert alert-danger">
            Erro ao tentar cadastrar usuário
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <input
              {...register('firstName', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.firstName ? 'is-invalid' : ''
              }`}
              placeholder="Nome"
              name="firstName"
            />
            <div className="invalid-feedback d-block">
              {errors.firstName?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('lastName', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.lastName ? 'is-invalid' : ''
              }`}
              placeholder="Sobrenome"
              name="lastName"
            />
            <div className="invalid-feedback d-block">
              {errors.lastName?.message}
            </div>
          </div>

          {isDriverRegister && (
            <div className="mb-2">
              <Controller
                name="kmPrice"
                rules={{ required: 'Campo obrigatório' }}
                control={control}
                render={({ field }) => (
                  <CurrencyInput
                    placeholder="Custo por Km - R$"
                    className={`form-control base-input ${
                      errors.kmPrice ? 'is-invalid' : ''
                    }`}
                    disableGroupSeparators={true}
                    value={field.value}
                    onValueChange={field.onChange}
                  />
                )}
              />
              <div className="text-start invalid-feedback d-block">
                {errors.kmPrice?.message}
              </div>
            </div>
          )}

          <div className="mb-2">
            <input
              {...register('phoneNumber', {
                required: 'Campo obrigatório',
                minLength: {
                  value: 11,
                  message: 'O número de telefone deve conter 11 dígitos',
                },
              })}
              type="text"
              className={`form-control base-input ${
                errors.email ? 'is-invalid' : ''
              }`}
              value={phoneNumber}
              placeholder="Contato"
              name="celphone"
              onChange={handlePhoneNumberChange}
              maxLength={15}
            />
            <div className="invalid-feedback d-block">
              {errors.phoneNumber?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('email', {
                required: 'Campo obrigatório',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Email inválido',
                },
              })}
              type="email"
              className={`form-control base-input ${
                errors.email ? 'is-invalid' : ''
              }`}
              placeholder="Email"
              name="email"
              autoComplete="off"
            />
            <div className="invalid-feedback d-block">
              {errors.email?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('password', {
                required: 'Campo obrigatório',
              })}
              type="password"
              className={`form-control base-input ${
                errors.password ? 'is-invalid' : ''
              }`}
              placeholder="Senha"
              name="password"
              autoComplete="off"
            />
            <div className="invalid-feedback d-block">
              {errors.password?.message}
            </div>
          </div>

          {isCompanySingUp && (
            <div className="mb-2">
              <input
                {...register('costCenter', {
                  required: 'Campo obrigatório',
                })}
                type="text"
                className={`form-control base-input ${
                  errors.costCenter ? 'is-invalid' : ''
                }`}
                placeholder="Centro de custo"
                name="costCenter"
              />
              <div className="invalid-feedback d-block">
                {errors.costCenter?.message}
              </div>
            </div>
          )}

          {isManagerSingup && (
            <div className="mb-5">
              <label className="me-2">Permitir acesso ao faturamento?</label>

              <label className="me-2" htmlFor="faturation">
                Sim
              </label>
              <input
                className="me-2"
                type="radio"
                id="faturation"
                value={'true'}
                {...register('faturation', {
                  required: 'Campo obrigatório',
                })}
              />
              <label className="me-2" htmlFor="noFaturation">
                Não
              </label>
              <input
                type="radio"
                id="noFaturation"
                value={'false'}
                {...register('faturation', {
                  required: 'Campo obrigatório',
                })}
              />

              <div className="invalid-feedback d-block">
                {errors.faturation?.message}
              </div>
            </div>
          )}

          <div className="login-submit mb-5">
            {isLoging ? (
              <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#4fa94d"
                ariaLabel="three-dots-loading"
                visible={true}
                wrapperClass="login-loading"
              />
            ) : (
              <ButtonIcon text="Fazer cadastro" />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Singup;
