import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';

import ButtonIcon from 'components/Buttons/ButtonIcon';
import ThreeDotsLoader from 'components/Loaders/ThreeDotsLoader';

import { RequestCompanyData } from 'utils/requests/company/companyRequests.types';
import { requestBackEndSaveCompany } from 'utils/requests/company/companyRequests';
import {
  extractNumbers,
  formatPhoneNumber,
} from 'utils/formatters/fomatterInput';

const RegisterCompany = () => {
  const history = useHistory();
  const [isLoging, setIsLoging] = useState(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RequestCompanyData>();

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhone);
    setValue('celphone', formattedPhone);
  };

  const onSubmitSaveCompany = (formData: RequestCompanyData) => {
    setIsLoging(true);

    const formattedKmPrice: string = String(formData.kmPrice).replace(',', '.');
    const formattedPhoneNumber: string = extractNumbers(formData.celphone);

    const formattedPayload: RequestCompanyData = {
      address: formData.address,
      celphone: formattedPhoneNumber,
      email: formData.email,
      kmPrice: Number(formattedKmPrice),
      name: formData.name,
    };

    requestBackEndSaveCompany(formattedPayload)
      .then(() => {
        Swal.fire('Sucesso!', 'Cadastro realizado!', 'success').then(() => {
          history.push('/myApp/menu/admin/my-companies');
        });
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setIsLoging(false);
      });
  };

  return (
    <div className="container">
      <div className="base-card login-card mt-5">
        <h1>Cadastrar empresa parceira</h1>

        {hasError && (
          <div className="alert alert-danger">
            Houve um erro ao tentar cadastrar o parceiro
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmitSaveCompany)}>
          <div className="mb-2">
            <input
              {...register('name', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.name ? 'is-invalid' : ''
              }`}
              placeholder="Nome"
              name="name"
            />
            <div className="invalid-feedback d-block">
              {errors.name?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('address', {
                required: 'Campo obrigatório',
              })}
              type="text"
              className={`form-control base-input ${
                errors.address ? 'is-invalid' : ''
              }`}
              placeholder="Endereço"
              name="address"
            />
            <div className="invalid-feedback d-block">
              {errors.address?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('email', {
                required: 'Campo obrigatório',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Email inválido',
                },
              })}
              type="text"
              className={`form-control base-input ${
                errors.email ? 'is-invalid' : ''
              }`}
              placeholder="Email"
              name="email"
            />
            <div className="invalid-feedback d-block">
              {errors.email?.message}
            </div>
          </div>

          <div className="mb-2">
            <input
              {...register('celphone', {
                required: 'Campo obrigatório',
                minLength: {
                  value: 11,
                  message: 'O número de telefone deve conter pelo menos 11 dígitos',
                },
              })}
              type="text"
              className={`form-control base-input ${
                errors.email ? 'is-invalid' : ''
              }`}
              value={phoneNumber}
              placeholder="Contato"
              name="celphone"
              onChange={handlePhoneNumberChange}
              maxLength={15}
            />
            <div className="invalid-feedback d-block">
              {errors.celphone?.message}
            </div>
          </div>

          <div className="mb-4">
            <input
              {...register('kmPrice', {
                required: 'Campo obrigatório',
                pattern: {
                  value: /^\d+,?\d*$/,
                  message: 'Número inválido',
                },
              })}
              type="text"
              className={`form-control base-input ${
                errors.kmPrice ? 'is-invalid' : ''
              }`}
              placeholder="Valor R$ por Km"
              name="kmPrice"
            />
            <div className="text-start invalid-feedback d-block">
              {errors.kmPrice?.message}
            </div>
          </div>

          <div className="login-submit mb-5">
            {isLoging ? (
              <ThreeDotsLoader />
            ) : (
              <ButtonIcon text="Fazer cadastro" />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterCompany;
