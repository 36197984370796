import {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import {getAuthData} from 'utils/storage';
import {Expense, Status} from 'utils/types/entities.types';
import {hasAnyRoles} from 'utils/requests/auth/auth';
import {formatExpenseValue} from 'utils/formatters/formatterMoney';
import {requestBackEndUserById} from 'utils/requests/user/userRequests';
import {requestBackendRaceById} from 'utils/requests/ride/rideRequests';
import {CompanyUser} from 'utils/requests/company/companyRequests.types';
import {PendingRidesResponse} from 'utils/requests/ride/rideRequests.types';
import {requestFinishRideInvoice, requestRemoveDriverFromRide,} from 'utils/requests/expenses/expensesRequest';
import {DateFormatter, HourFormatter, RideDateFormatter,} from 'utils/formatters/formatterResponse';

import ItemDetails from '../../ItemDetails';
import RideKm from 'components/CurrentRide/RideKm';
import DriverFee from 'components/Expenses/DriverFee';
import RideExpense from 'components/CurrentRide/RideExpense';

import './styles.css';
import {BiMinus, BiPlus} from 'react-icons/bi';
import {LuEqual} from 'react-icons/lu';
import {NewObservation} from 'components/CurrentRide/newObservation';

type UrlParams = {
    rideId: string;
};

type Props = {
    isFromAdmin?: boolean;
};

const RideDetails = ({isFromAdmin = false}: Props) => {
    const MySwal = withReactContent(Swal);
    const [faturationAccess, setFaturationAccess] = useState<boolean>(true);
    const {rideId} = useParams<UrlParams>();
    const [rideExpenses, setRideExpenses] = useState<Expense[]>([]);
    const [race, setRace] = useState<PendingRidesResponse>();
    const history = useHistory();
    const [status, setStatus] = useState<Status[]>([]);

    const requestRideById = useCallback(() => {
        requestBackendRaceById(rideId)
            .then((response) => {
                const responseData: PendingRidesResponse = response.data;
                setRace(responseData);
                setRideExpenses(responseData.ride.expenses);
                if (hasAnyRoles(['ROLE_ADMIN', 'ROLE_OPERATOR'])) {
                    setStatus(responseData.status);
                } else {
                    setStatus(
                        responseData.status.filter(
                            (item) => !item.status.includes('Encaminhado'),
                        ),
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [rideId]);

    const onClickAddCost = () => {
        MySwal.fire({
            title: 'Inserir custo',
            html: <RideExpense rideId={Number(rideId)}/>,
            showConfirmButton: false,
        }).then(() => {
            requestRideById();
        });
    };

    const onClickAddDriverQualityCost = (isQualityCost?: boolean) => {
        MySwal.fire({
            title: isQualityCost
                ? 'Inserir valor total Quality'
                : 'Inserir lucro motorista',
            html: <DriverFee rideId={Number(rideId)} isQualityCost={isQualityCost}/>,
            showConfirmButton: false,
        }).then(() => {
            requestRideById();
        });
    };

    const onClickAddKm = () => {
        MySwal.fire({
            title: 'Inserir Km percorrido',
            html: <RideKm rideId={Number(rideId)}/>,
            showConfirmButton: false,
        }).then(() => {
            requestRideById();
        });
    };

    const handleSubmitNewObservation = () => {
        MySwal.fire({
            title: 'Digita abaixo sua observação',
            html: <NewObservation rideId={Number(rideId)}/>,
            showConfirmButton: false,
        }).then(() => {
            requestRideById();
        });
    };

    const handleRemoveDriver = () => {
        MySwal.fire({
            title: 'Deseja realmente remover o motorista?',
            icon: 'info',
            confirmButtonText: 'Remover',
            showCancelButton: true,
            confirmButtonColor: '#004b23',
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#dc3545',
        }).then((result) => {
            if (result.isConfirmed) {
                requestRemoveDriverFromRide(rideId)
                    .then(() => {
                        Swal.fire({
                            title: 'Motorista removido!',
                            text: 'Essa corrida voltou para área de corridas pendentes!',
                            confirmButtonColor: '#004b23',
                            icon: 'success',
                        }).finally(() => {
                            history.push('/myApp/menu/dashboard');
                        });
                    })
                    .catch(() => {
                        Swal.fire({
                            title: 'Oops!',
                            text: 'Houve um erro ao tentar remover o faturamento',
                            confirmButtonColor: '#dc3545',
                            icon: 'error',
                        });
                    });
            }
        });
    };

    const onClickFinishInvoice = () => {
        MySwal.fire({
            title: 'Deseja realmente finalizar o faturamento?',
            icon: 'info',
            confirmButtonText: 'Finalizar',
            showCancelButton: true,
            confirmButtonColor: '#004b23',
            cancelButtonText: 'Cancelar',
            cancelButtonColor: '#dc3545',
        }).then((result) => {
            if (result.isConfirmed) {
                requestFinishRideInvoice(rideId)
                    .then(() => {
                        Swal.fire({
                            title: 'Faturamento finalizado com sucesso!',
                            confirmButtonColor: '#004b23',
                            icon: 'success',
                        }).finally(() => {
                            history.push('/myApp/menu/dashboard');
                        });
                    })
                    .catch(() => {
                        Swal.fire({
                            title: 'Oops!',
                            text: 'Houve um erro ao tentar finalizar o faturamento',
                            confirmButtonColor: '#dc3545',
                            icon: 'error',
                        });
                    });
            }
        });
    };

    useEffect(() => {
        requestRideById();
    }, [requestRideById]);

    useEffect(() => {
        if (hasAnyRoles(['ROLE_COMPANY'])) {
            const managerId = getAuthData().userId;

            requestBackEndUserById(String(managerId)).then((response) => {
                const managerData: CompanyUser = response.data;

                if (!managerData.faturation) {
                    setFaturationAccess(false);
                }
            });
        }
    }, []);

    return (
        <div className="race-details-container p-3 mt-5 base-card">
            <div className="race-details-solicitation-card p-3 container">
                {hasAnyRoles(['ROLE_OPERATOR']) && race?.ride?.observations && (
                    <>
                        <div className="btn-group mb-3 ">
                            <button
                                onClick={handleSubmitNewObservation}
                                className="btn btn-outline-warning mb-3 mt-3"
                            >
                                Nova observação <BiPlus/>
                            </button>
                            <button
                                onClick={handleRemoveDriver}
                                className="btn btn-outline-danger mb-3 mt-3"
                            >
                                Remover motorista <BiMinus/>
                            </button>
                        </div>

                        <ItemDetails
                            itemTitle={'Observação'}
                            itemData={race.ride.observations}
                        />
                    </>
                )}

                <h1 className="page-title">Sobre a corrida</h1>
                {race ? (
                    <>
                        {hasAnyRoles(['ROLE_ADMIN']) && (
                            <>
                                <div className="btn-group mb-3 ">
                                    <button
                                        onClick={handleSubmitNewObservation}
                                        className="btn btn-outline-warning mb-3 mt-3"
                                    >
                                        Nova observação <BiPlus/>
                                    </button>
                                    <button
                                        onClick={handleRemoveDriver}
                                        className="btn btn-outline-danger mb-3 mt-3"
                                    >
                                        Remover motorista <BiMinus/>
                                    </button>
                                </div>

                                <ItemDetails
                                    itemTitle={'Observação'}
                                    itemData={race.ride.observations}
                                />

                                <div className="btn-group mb-3 ">
                                    <button className="btn btn-outline-success mt-3">
                                        Despesas: R$ {race.ride.totalExpenses}
                                    </button>
                                    <button className="btn btn-outline-success mt-3">
                                        <BiPlus/>
                                    </button>
                                    <button className="btn btn-outline-success mt-3 ">
                                        Lucro motorista: R$ {race.ride.driverFeeWithoutExpense}
                                    </button>
                                    <button className="btn btn-outline-success mt-3">
                                        <BiPlus/>
                                    </button>
                                    <button className="btn btn-outline-success mt-3 ">
                                        Lucro Quality: R$ {race.ride.qualityProfit}
                                    </button>
                                    <button className="btn btn-outline-success mt-3">
                                        <LuEqual/>
                                    </button>
                                    <button className="btn btn-outline-success mt-3 ">
                                        Total cliente: R$ {race.ride.totalClient}
                                    </button>
                                </div>
                            </>
                        )}
                        <ItemDetails itemTitle={'Id'} itemData={race.ride.externalRideId ?? race.ride.id!}/>
                        <ItemDetails
                            itemTitle={'Favorecido'}
                            itemData={
                                race.ride.favoredName ??
                                race.passenger.firstName + ' ' + race.passenger.lastName
                            }
                        />
                        <ItemDetails itemTitle={'Contato'} itemData={race.ride.contact}/>
                        <ItemDetails
                            itemTitle={'Centro de Custo'}
                            itemData={race.ride.costCenter}
                        />
                        <ItemDetails
                            itemTitle={'Data'}
                            itemData={RideDateFormatter(race.ride.scheduleDate)}
                        />
                        <ItemDetails
                            itemTitle={'Horário'}
                            itemData={`${race.ride.scheduleTime} h`}
                        />
                        <ItemDetails
                            itemTitle={'Cidade de origem'}
                            itemData={race.ride.originCity}
                        />

                        <ItemDetails
                            itemTitle={'Endereço de Origem'}
                            itemData={race.ride.originAddress}
                        />
                        <ItemDetails
                            itemTitle={'Cidade de destino'}
                            itemData={race.ride.destinyCity}
                        />
                        <ItemDetails
                            itemTitle={'Endereço de Destino'}
                            itemData={race.ride.destinyAddress}
                        />

                        {status.map((status) => (
                            <ItemDetails
                                key={status.id}
                                itemTitle="Status"
                                itemData={`${
                                    status.status +
                                    ' - ' +
                                    DateFormatter(status.date) +
                                    ' - ' +
                                    HourFormatter(status.date)
                                }`}
                            />
                        ))}
                    </>
                ) : (
                    <h3 className="page-subtitle">Não há corrida</h3>
                )}
            </div>

            <div className="container">
                <hr className="details-separator"/>
            </div>

            {hasAnyRoles(['ROLE_DRIVER']) && race != null && (
                <div className="race-details-driver-card p-3 container">
                    <h1 className="page-title">Total despesas</h1>
                    <ItemDetails
                        itemTitle="Despesas totais"
                        itemData={formatExpenseValue(race?.ride.totalExpenses ?? 0)}
                    />
                    <ItemDetails
                        itemTitle="Lucro motorista"
                        itemData={formatExpenseValue(
                            race?.ride.fee - race?.ride.totalExpenses,
                        )}
                    />
                    <ItemDetails
                        itemTitle="Total"
                        itemData={formatExpenseValue(race?.ride.fee ?? 0)}
                    />
                    <hr className="details-separator"/>
                </div>
            )}

            {!hasAnyRoles(['ROLE_PASSENGER']) && faturationAccess && (
                <div className="race-details-driver-card p-3 container">
                    <h1 className="page-title">Sobre os custos</h1>
                    {race ? (
                        <>
                            {hasAnyRoles(['ROLE_ADMIN']) && (
                                <div className="btn-group mb-3 ">
                                    <button className="btn btn-outline-success mt-3">
                                        Despesas: R$ {race.ride.totalExpenses}
                                    </button>
                                    <button className="btn btn-outline-success mt-3">
                                        <BiPlus/>
                                    </button>
                                    <button className="btn btn-outline-success mt-3 ">
                                        Lucro motorista: R$ {race.ride.driverFeeWithoutExpense}
                                    </button>
                                    <button className="btn btn-outline-success mt-3">
                                        <BiPlus/>
                                    </button>
                                    <button className="btn btn-outline-success mt-3 ">
                                        Lucro Quality: R$ {race.ride.qualityProfit}
                                    </button>
                                    <button className="btn btn-outline-success mt-3">
                                        <LuEqual/>
                                    </button>
                                    <button className="btn btn-outline-success mt-3 ">
                                        Total cliente: R$ {race.ride.totalClient}
                                    </button>
                                </div>
                            )}

                            <ItemDetails
                                itemTitle="Km percorrido"
                                itemData={
                                    race.ride.km ? String(race.ride.km) : 'Km não registrado'
                                }
                            />

                            {rideExpenses.map((expense) => (
                                <div className="ride-details-expenses-container">
                                    <ItemDetails
                                        key={expense.id}
                                        itemTitle={expense.expense}
                                        itemData={expense.spend}
                                        expenseId={expense.id}
                                        onFetchRide={requestRideById}
                                        expenseType
                                    />
                                </div>
                            ))}
                            <ItemDetails
                                itemTitle="Total despesas"
                                itemData={formatExpenseValue(race.ride.totalExpenses)}
                            ></ItemDetails>

                            {hasAnyRoles(['ROLE_ADMIN', 'ROLE_OPERATOR']) && (
                                <>
                                    <ItemDetails
                                        itemTitle="Lucro motorista "
                                        itemData={formatExpenseValue(
                                            race.ride.driverFeeWithoutExpense,
                                        )}
                                    />

                                    <ItemDetails
                                        itemTitle="Total motorista ( + despesas )"
                                        itemData={formatExpenseValue(race.ride.fee ?? 0)}
                                    />

                                    {hasAnyRoles(['ROLE_ADMIN']) && (
                                        <ItemDetails
                                            itemTitle="Lucro quality"
                                            itemData={formatExpenseValue(race.ride.qualityProfit)}
                                        />
                                    )}
                                </>
                            )}

                            {hasAnyRoles(['ROLE_ADMIN', 'ROLE_COMPANY']) && (
                                <ItemDetails
                                    itemTitle="Total"
                                    itemData={formatExpenseValue(race.ride.totalClient)}
                                />
                            )}

                            <div className="ride-details-expense-btn-container">
                                {hasAnyRoles(['ROLE_ADMIN']) && (
                                    <>
                                        <button
                                            className="btn btn-secondary text-white"
                                            onClick={() => onClickAddDriverQualityCost(true)}
                                        >
                                            TOTAL R$
                                        </button>
                                        <button
                                            className="btn btn-tertiary text-white"
                                            onClick={() => onClickAddDriverQualityCost()}
                                        >
                                            MOTORISTA R$
                                        </button>
                                    </>
                                )}
                                {hasAnyRoles(['ROLE_ADMIN', 'ROLE_OPERATOR']) && (
                                    <>
                                        <button
                                            className="btn btn-success text-white"
                                            onClick={() => onClickAddCost()}
                                        >
                                            CORRIDA R$
                                        </button>
                                        <button
                                            className="btn btn-quaternary text-white"
                                            onClick={() => onClickAddKm()}
                                        >
                                            ADICIONAR KM PERCORRIDO
                                        </button>
                                        {!race.ride.fatured && (
                                            <button
                                                className="btn btn-info text-white"
                                                onClick={() => onClickFinishInvoice()}
                                            >
                                                FINALIZAR FATURAMENTO
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <h3 className="page-subtitle">Sem atribuição de custos.</h3>
                    )}
                </div>
            )}

            <div className="container">
                <hr className="details-separator"/>
            </div>

            <div className="race-details-driver-card p-3 container">
                <h1 className="page-title">Sobre o motorista</h1>
                {race?.car ? (
                    <>
                        <ItemDetails
                            itemTitle={'Motorista'}
                            itemData={`${
                                race.car.driver?.firstName + ' ' + race.car.driver?.lastName
                            }`}
                        />
                        {race?.car?.driver?.phoneNumber && (
                            <ItemDetails
                                itemData={race.car.driver.phoneNumber}
                                itemTitle="Telefone"
                            />
                        )}
                        <ItemDetails itemTitle="Email" itemData={race.car.driver!.email}/>
                        {hasAnyRoles(['ROLE_ADMIN', 'ROLE_DRIVER']) && (
                            <ItemDetails
                                itemTitle="Valor por KM"
                                itemData={`R$ ` + race.car.driver!.kmPrice}
                            />
                        )}
                        <ItemDetails itemTitle="Veículo" itemData={race.car.vehicle}/>
                        <ItemDetails itemTitle="Placa" itemData={race.car.plate}/>
                        <ItemDetails itemTitle="Cor" itemData={race.car.color}/>
                    </>
                ) : (
                    <h3 className="page-subtitle">Pendente de motorista</h3>
                )}
            </div>

            <div className="container">
                <hr className="details-separator"/>
            </div>

            <div className="details-driver-card p-3 container">
                <h1 className="page-title">Sobre o solicitante</h1>
                {race?.passenger ? (
                    <>
                        <ItemDetails itemTitle="Nome" itemData={race.ride.applicantName}/>
                        <ItemDetails
                            itemTitle="Email"
                            itemData={race.ride.applicantEmail}
                        />
                    </>
                ) : (
                    <h3 className="page-subtitle">Dados não encontrados</h3>
                )}
            </div>

            {isFromAdmin && (
                <>
                    <div className="container">
                        <hr className="details-separator"/>
                    </div>

                    <div className="race-details-driver-card p-3 container">
                        <h1 className="page-title">Sobre a empresa</h1>
                        {race?.company ? (
                            <>
                                <ItemDetails itemTitle="Nome" itemData={race.company.name}/>
                                <ItemDetails
                                    itemTitle="Endereço"
                                    itemData={race.company.address}
                                />
                                <ItemDetails
                                    itemTitle="Telefone"
                                    itemData={race.company.celphone}
                                />
                                <ItemDetails itemTitle="Email" itemData={race.company.email}/>
                                {hasAnyRoles(['ROLE_ADMIN']) && (
                                    <ItemDetails
                                        itemTitle="Valor por KM"
                                        itemData={`R$` + race.company.kmPrice}
                                    />
                                )}
                            </>
                        ) : (
                            <h3 className="page-subtitle">Dados não encontrados</h3>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default RideDetails;
