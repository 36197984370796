import InvoiceById from 'components/InvoiceById';
import RideHistory from 'components/RideHistory';
import PrivateRoute from 'components/PrivateRoute';
import RideDetails from 'components/RideHistory/RideDetails';
import ListInvoiceByCompany from 'components/ListInvoice/ListInvoice';

import Operator from 'pages/Admin/Operator';
import Singup from 'pages/MyApp/Auth/Singup';
import PendingRides from 'pages/Admin/PendingRides';
import MyDriversAdmin from 'pages/Admin/MyDriversAdmin';
import CompanyDetails from 'pages/Admin/CompanyDetails';
import DashboardAdmin from 'pages/Admin/DashboardAdmin';
import RegisterCompany from 'pages/Admin/RegisterCompany';
import MyCompaniesAdmin from 'pages/Admin/MyCompaniesAdmin';
import OperatorDetails from 'pages/Admin/Operator/OperatorDetails';
import FaturationRidesAdmin from 'pages/Admin/FaturationRidesAdmin';
import Invoice from 'pages/Admin/DashboardAdmin/components/Invoice';
import DriverDetails from 'pages/Admin/MyDriversAdmin/DriverDetails';
import PendingRideDetails from 'pages/Admin/PendingRides/PendingRideDetails';

import RequestRideOperator from '../../../../components/RequestRideOperator';

const AdminRoutes = () => {
  return (
    <>
      <PrivateRoute path="/myApp/menu/dashboard" roles={['ROLE_ADMIN']}>
        <DashboardAdmin />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/dashboard/faturation-rides"
        roles={['ROLE_ADMIN']}
      >
        <FaturationRidesAdmin />
      </PrivateRoute>

      <PrivateRoute path="/myApp/menu/admin/pending-rides">
        <PendingRides />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/pending-rides/:rideId"
        roles={['ROLE_ADMIN']}
      >
        <PendingRideDetails />
      </PrivateRoute>

      <PrivateRoute path="/myApp/menu/ride-history" roles={['ROLE_ADMIN']}>
        <RideHistory requestEntity={'ADMIN'} />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/ride-history/:rideId"
        roles={['ROLE_ADMIN']}
      >
        <RideDetails isFromAdmin />
      </PrivateRoute>

      <PrivateRoute path="/myApp/menu/admin/my-drivers" roles={['ROLE_ADMIN']}>
        <MyDriversAdmin />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/my-drivers/register-driver"
        roles={['ROLE_ADMIN']}
      >
        <Singup isDriverRegister requestEntity="DRIVER" />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/my-drivers/details/:driverId"
        roles={['ROLE_ADMIN']}
      >
        <DriverDetails />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/my-companies"
        roles={['ROLE_ADMIN']}
      >
        <MyCompaniesAdmin />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/my-companies/register-company"
        roles={['ROLE_ADMIN']}
      >
        <RegisterCompany />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/my-companies/:companyId/:companyName"
        roles={['ROLE_ADMIN']}
      >
        <CompanyDetails />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/registerManager/:companyId/:companyName"
        roles={['ROLE_ADMIN']}
      >
        <Singup isManagerSingup requestEntity="MANAGER" />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/my-operators"
        roles={['ROLE_ADMIN']}
      >
        <Operator />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/my-operators/register"
        roles={['ROLE_ADMIN']}
      >
        <Singup requestEntity="OPERATOR" isOperatorSingup />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/my-operators/details/:operatorId"
        roles={['ROLE_ADMIN']}
      >
        <OperatorDetails />
      </PrivateRoute>

      <PrivateRoute path="/myApp/menu/admin/invoice" roles={['ROLE_ADMIN']}>
        <Invoice />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/company-invoice/:companyId/:companyName"
        roles={['ROLE_ADMIN']}
      >
        <ListInvoiceByCompany />
      </PrivateRoute>

      <PrivateRoute
        path="/myApp/menu/admin/company-invoice-by-id/:companyId/:invoiceId"
        roles={['ROLE_ADMIN']}
      >
        <InvoiceById />
      </PrivateRoute>
      <PrivateRoute path="/myApp/menu/admin/new-ride" roles={['ROLE_ADMIN']}>
        <RequestRideOperator />
      </PrivateRoute>
    </>
  );
};

export default AdminRoutes;
