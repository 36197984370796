import { useForm } from 'react-hook-form';
import '../style.css';
import DriverFilterData from '../types/DriverFilterData';

type Props = {
  onSubmitFilter: (data: DriverFilterData) => void;
};

const DriverFilter = ({ onSubmitFilter }: Props) => {
  const { register, handleSubmit, setValue } = useForm<DriverFilterData>();

  const handleFormClear = () => {
    setValue('startDate', null);
    setValue('endDate', null);
  };

  const onSubmit = (formData: DriverFilterData) => {
    const payload: DriverFilterData = {
      startDate: formData.startDate || null,
      endDate: formData.endDate || null,
    };
    onSubmitFilter(payload);
  };

  return (
    <div className="invoice-filter-container">
      <form onSubmit={handleSubmit(onSubmit)} className="invoice-filter-form">
        <div className="invoice-filter-city-container">
          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="startDate">
              Início do período:
            </label>
            <input
              {...register('startDate')}
              id="startDate"
              type="date"
              className="form-control"
              placeholder="Data inicio"
              name="startDate"
            />
          </div>

          <div className="invoice-filter-city">
            <label className="mb-2" htmlFor="endDate">
              Fim do período:
            </label>
            <input
              {...register('endDate')}
              id="endDate"
              type="date"
              className="form-control"
              placeholder="Data fim"
              name="endDate"
            />
          </div>
        </div>
        <div className="invoice-filter-btn-container">
          <button
            type="button"
            onClick={handleFormClear}
            className="btn btn-outline-secondary"
          >
            LIMPAR
          </button>

          <button type="submit" className="btn btn-secondary ">
            FILTRAR
          </button>
        </div>
      </form>
    </div>
  );
};

export default DriverFilter;
