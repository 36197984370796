import { useForm } from 'react-hook-form';

import Swal from 'sweetalert2';

import { RideId } from 'utils/types/entities.types';
import {
  requestSetRideValues,
  requestSetRideValuesKm,
} from 'utils/requests/ride/rideRequests';

type FormData = {
  value: number;
};

type Props = {
  changeRideValuesPayload: RideId[];
  isChangeValuePerKm: boolean;
  updateList: boolean;
  setUpdateList: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangeRideValues = (props: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    if (props.isChangeValuePerKm) {
      requestSetRideValuesKm(props.changeRideValuesPayload, formData.value)
        .then(() => {
          Swal.close();
          Swal.fire({
            title: 'Valores alterados com sucesso',
            icon: 'success',
          });
          props.setUpdateList(!props.updateList);
        })
        .catch((error) => {
          Swal.fire('Oops', `${error.response.data.message}`, 'error');
        });
    } else {
      requestSetRideValues(props.changeRideValuesPayload, formData.value)
        .then(() => {
          Swal.close();
          Swal.fire({
            title: 'Valores alterados com sucesso',
            icon: 'success',
          });
          props.setUpdateList(!props.updateList);
        })
        .catch((error) => {
          Swal.fire('Oops', `${error.response.data.message}`, 'error');
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="col-lg mb-4">
        <input
          {...register('value', {
            required: 'Campo obrigatório',
            pattern: {
              value: /^\+?(0|[1-9]\d*)$/gm,
              message: 'Número inválido',
            },
          })}
          type="number"
          className={`form-control base-input ${
            errors.value ? 'is-invalid' : ''
          }`}
          placeholder={
            props.isChangeValuePerKm
              ? 'Km total de cada corrida passará a ser: '
              : 'Valor de cada corrida passará a ser:'
          }
        />
        <div className="invalid-feedback d-block">{errors.value?.message}</div>
      </div>

      <div className="ride-expense-btn-container">
        <button className="btn btn-tertiary ride-expense-btn" type="submit">
          Salvar
        </button>
        <button
          className="btn btn-danger ride-expense-btn"
          type="button"
          onClick={() => Swal.close()}
        >
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default ChangeRideValues;
